
























































































































































































































































































































































































































































































































































  import Vue from "vue";
  import Component from "vue-class-component";
  import { getModule } from "vuex-module-decorators";
  import { Watch } from "vue-property-decorator";
  import Debounce from "lodash/debounce";
  const { v4: uuidv4 } = require("uuid");
  import Masonry from "masonry-layout";

  import BaseComponent from "@/components/base-component.vue";
  import ProductSlider from "@/components/product-slider/product-slider.vue";
  import SearchTitle from "@/components/search-title/search-title.vue";
  import PackageOffers from "@/components/package-offers/package-offers.vue";
  import PackageOffersBanner from "@/components/package-offers-banner/package-offers-banner.vue";
  import ResultsItem from "@/components/results-item/results-item.vue";
  import PhotoPreview from "@/components/photo-preview/photo-preview.vue";
  import PhotoFrame from "@/components/photo-frame/photo-frame.vue";
  import PhotosWithFrame from "@/components/photos-with-frame/photos-with-frame.vue";
  import Snackbar from "@/components/snackbar/snackbar.vue";
  // import Modal from "@/components/modal/modal.vue";
  import SearchRunner from "@/components/home/search-runner.vue";
  import CapturePhoto from "@/components/capture-photo/capture-photo.vue";
  import Loader from "@/components/loader.vue";
  import NavTabs from "@/components/nav-tabs/nav-tabs.vue";
  import Pagination from "@/components/pagination/pagination.vue";
  import BannerSlider from "@/components/banner-slider/banner-slider.vue";
  import FindMore from "@/components/find-more/find-more.vue";
  import BaseIcon from "@/components/base-icon/base-icon.vue";
  import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
  import IconCheckCircle from "@/components/icons/icon-check-circle.vue";
  import NoDataRafiki from "@/components/illustrations/no-data-rafiki.vue";
  import { CatalogState } from "@/store/modules/catalog";
  import { CartState } from "@/store/modules/cart";
  import { SearchState } from "@/store/modules/search";
  import { EventsState } from "@/store/modules/events";
  import { InvoicesState } from "@/store/modules/invoices";
  import { CaptureState } from "@/store/modules/capture";
  import { SettingsState } from "@/store/modules/settings";
  import { ProductTypes } from "@/product-types";
  import { OfferTypes } from "@/offer-types";
  import { OfferStates } from "@/offer-states";
  import { Banners } from "@/banners";
  import { SBP } from "@/SBP";
  import Common from "@/plugins/common";

  @Component({
    name: "SearchResults",
    props: {
      eventId_: String,
      eventName_: String,
      eventDate_: String,
      runnerNum_: String,
      runnerFrameValues_: String,
      byFace_: String,
      faceImage_: String,
      selfieId_: String,
      personId_: String,
      photos_: String,
      notFound_: String,
      backPageData_: Object,
      isAllPhotosVisible_: Boolean,
      searchToken_: Object,
      recentSearch_: Object,
    },
    components: {
      ProductSlider,
      SearchTitle,
      PackageOffers,
      PackageOffersBanner,
      ResultsItem,
      PhotoPreview,
      PhotoFrame,
      PhotosWithFrame,
      Snackbar,
      // Modal,
      SearchRunner,
      CapturePhoto,
      Loader,
      NavTabs,
      Pagination,
      BannerSlider,
      FindMore,
      BaseIcon,
      BaseIllustration,
      IconCheckCircle,
      NoDataRafiki,
    },
  })
  export default class SearchResults extends BaseComponent {

    private readonly catalogState: CatalogState = getModule(CatalogState, this.$store);
    private readonly cartState: CartState = getModule(CartState, this.$store);
    private readonly searchState: SearchState = getModule(SearchState, this.$store);
    private readonly eventsState: EventsState = getModule(EventsState, this.$store);
    private readonly invoicesState: InvoicesState = getModule(InvoicesState, this.$store);
    private readonly captureState: CaptureState = getModule(CaptureState, this.$store);
    private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

    private debouncedDoSearch: any = null;

    productTypes: any = ProductTypes;
    banners: any[] = Banners;
    catalogProducts: any[] = [];
    frameProducts: any[] = [];
    
    itemDropdown: boolean = false;
    itemPhotoPrice: number = 0;

    hoveredItemIndex: number = -1;

    eventId: string = "";
    eventName: string = "";
    eventDate: string = "";
    isAllPhotosVisible: boolean = true;
    runnerNum: string = "";
    runnerLastName: string = "";
    runnerFrameValues: any = null;
    byFace: boolean = false;

    searchToken: any = null;
    allPhotosTokenValue: string = "";

    tagged: boolean = false;

    faceImage: string = "";
    selfieId: string = "";
    personId: string = "";
    
    photos: any[] = [];
    photosAddingToCart: any[] = [];
    photosInCart: string[] = [];
    notFound__: string = "";

    photoLoaderBlock: any = null;
    updating: boolean = false;
    startFrom: number = 0;

    currentPage: number = 1;

    isButtonInvisible: boolean = false;
    
    packageOffers: any[] = [];
    showPackageOffers: boolean = false;
    packageOffersOptions: any[] = [];

    resultsType: number = 1;

    previewDialog: boolean = false;
    previewDialogKey: string = "";
    previewPhotos: any[] = [];
    previewChecked: boolean[] = [];
    currentIndex: number = 0;
    autoShowPhotoIndex: number = -1;
    autoShowPhotoId: string = "";

    photoFrameDialog: boolean = false;
    selectedPhoto: any = null;
    selectedPhotoId: string = "";
    selectedPhotoUrl: string = "";
    selectedFrameId: string = "";
    selectedFrameProductId: string = "";
    selectedFrameValues: any = null;
    allPhotosMode: boolean = false;
    photoFramesInCart: any[] = [];
    photosForFrame: any[] = [];
    photoFrameGift: any = null;
    showPhotoFrameFromItsMe: boolean = false;

    photosWithFrameDialog: boolean = false;
    photosWithFrameOptions: any = null;

    runnersContainerId: string = "";
    runnersDialog: boolean = false;
    runnersDialogKey: string = "";
    runnersHeight: string = "auto";
    
    captureDialog: boolean = false;
    input: any = null;
    selectedFile: File | null = null;
    redrawImage: boolean = false;
    searchResult: string = "";

    loading: boolean = true;
    isAddingFrame: boolean = false;
    isAddingPhotosWithFrame: boolean = false;
    isAddingPhotosWithMe: boolean = false;

    showSbp: boolean = false;

    resultsMasonryGrid: any = null;

    showFindMore: boolean = false;
    moreByPhoto: boolean = false;
    showFindMoreResults: boolean = false;
    showNotFoundMore: boolean = false;
    showErrorMore: boolean = false;
    moreSearching: boolean = false;
    moreFaceImage: string = "";
    moreRunnerNum: string = "";
    findMoreResults: any[] = [];
    itsMePhotos: string[] = [];
    previewItsMe: boolean = false;
    showFindMoreNotification: boolean = false;

    giftLabelPhotosWithMe: string = "";
    showSnackbar: boolean = false;

    get isMobile(): boolean {
      const r: any = this.$root;
      return r.isMobile;
    }

    get isTablet(): boolean {
      const r: any = this.$root;
      return r.isTablet;
    }

    get theme(): any {
      return this.settingsState.theme;
    }

    get tenantName(): any {
      return this.settingsState.tenantName;
    }

    get isMySportUSA(): boolean {
      return this.settingsState.isMySportUSA === true;
    }

    get cart(): any {
      return this.cartState.cart;
    }

    get cartItemsCount(): number {
      return this.cartState.itemsCount;
    }

    get cartTotalAmount(): number {
      return this.cartState.totalAmount;
    }

    get isFaceSearchEnabled(): boolean {
      return this.eventsState.event ? this.eventsState.event?.searchingPhotos?.byFace === true : false;
    }

    get notFound(): boolean {
      return this.notFound__ === "true";
    }

    get searchDisabled(): boolean {
      return (this.eventsState.event.searchingPhotos.byTag === false 
              && this.eventsState.event.searchingPhotos.byFace === false);
    }

    get totalPages(): number {
      return Math.ceil(this.searchState.photosTotal / this.photosPerPage);
    }

    get photosWithMeProduct(): any {
      const products: any[] = this.getCatalogProducts(this.productTypes.photos_with_me);
      if (products.length == 0) return null;
      return products[0];
    }

    get photoProduct(): any {
      const products: any[] = this.getCatalogProducts(this.productTypes.photo);
      if (products.length == 0) return null;
      return products[0];
    }

    get promocodeProduct(): any {
      const products: any[] = this.getCatalogProducts(this.productTypes.promocode);
      if (products.length == 0) return null;
      return products[0];
    }

    get photosWithFrameProduct(): any {
      const products: any[] = this.getCatalogProducts(this.productTypes.photosWithFrame);
      if (products.length == 0) return null;
      return products[0];
    }

    get sliderOptions() {
      return {
        frameProducts: this.photosWithFrameProduct ? [
          ...this.frameProducts,
          this.photosWithFrameProduct,
        ] : this.frameProducts,
        eventId: this.eventId,
        byFace: this.byFace,
        runnerNum: this.runnerNum,
        selfieId: this.selfieId,
        personId: this.personId,
      }
    }

    get photosWithMeDiscount(): number {
      if (!this.photosWithMeProduct) {
        return 0;
      }
      const discount = (this.itemPhotoPrice * this.searchState.photosTotal) - this.photosWithMeProduct.amount;
      return parseFloat((discount).toFixed(0));
    }

    get photosWithFrameDiscount(): number {
      if (!this.photosWithFrameProduct) {
        return 0;
      }
      const frameId = Common.getAttribute(this.photosWithFrameProduct.attributes, 'FrameId');
      const amount = this.getSingleFrameAmount(frameId);
      if (!amount) return 0;
      const discount = (amount * this.searchState.photosTotal) - this.photosWithFrameProduct.amount;
      return parseFloat((discount).toFixed(0));
    }

    get showPackageOffersBanner(): boolean {
      return this.hasPackageOffers && this.photosWithMeDiscount > 0 && this.$route.name !== 'event-photos';
    }

    get hasPackageOffers(): boolean {
      return this.packageOffers.length > 0 && this.packageOffers.filter((i: any) => i.size !== 1).length > 0;
    }

    get hasEventProductsInCart(): boolean {
      return this.isEventProductsInCart();
    }

    get lang(): string {
      return this.settingsState.lang;
    }

    get currencyAlertClosed(): boolean {
      return localStorage.getItem('currencyAlertClosed') === 'true';
    }

    set currencyAlertClosed(val) {
      localStorage.setItem('currencyAlertClosed', val.toString());
    }

    get bannerSliderItems(): any[] {
      return [];
    }

    get paymentDiscountItems(): any[] {
      const items = [
        { img: '/img/sbp/short-sbp.svg', text: this.$t('paymentDiscount/sbp'.toString()) },
      ];

      return this.showSbp ? items : [];
    }

    get itsMePhotosCount(): number {
      return this.itsMePhotos.filter((i: string) => i !== '').length;
    }

    get photosPerPage(): number {
      if (this.smOnly || this.mdOnly) return 12;
      return 50;
    }

    async updateSbpState(): Promise<void> {
      if (SBP.enabled !== true) return;

      await this.invoicesState.getPaymentTypes({ 
        amount: this.photoProduct.amount, 
        currencyId: this.photoProduct.currencyId,
        albumId: await this.getAlbumIds(),
      });

      const types: any[] = this.invoicesState.paymentTypes;
      const index = types.findIndex((r: any) => r.paymentTypeId === 'QuickPayment');
      if (this.theme === '' && this.tenantName !== 'Sporttiming' && index >= 0) {
        this.showSbp = true;
        return;
      }

      this.showSbp = false;
    }

    @Watch("photosWithMeProduct")
    async onPhotosWithMeChanged(): Promise<void> {
      if (this.photosWithMeProduct) {
        const gift = this.getGiftForProduct(this.photosWithMeProduct);
        if (gift) {
          const giftInCart = this.getCartItemsWithProductId(gift.product.productId).length > 0;
          const photosInCart = this.getCartItemsWithProductId(this.photosWithMeProduct.productId).length > 0;
          if (!giftInCart || !photosInCart) {
            this.giftLabelPhotosWithMe = this.getGiftLabelPhotosWithMe(gift);
            return;
          }
        }
      }
      this.giftLabelPhotosWithMe = '';
    }

    @Watch("cartItemsCount")
    async onCartItemsCountChanged(): Promise<void> {
      await this.onPhotosWithMeChanged();
    }

    async getAlbumIds(): Promise<string[]> {
      const albumId: string[] = [];
      
      if (!this.cartState.cart || this.cartState.cartItems.length === 0) {
        return [this.eventId];
      }
      
      const items: any[] = this.cartState.cartItems;
      for (let i = 0; i < items.length; i += 1) {
        const id: string = items[i].catalog?.catalogId || "";
        if (id && !albumId.includes(id)) albumId.push(id);
      }
      if (!albumId.includes(this.eventId)) albumId.push(this.eventId);
      
      return albumId;
    }

    setPictureHandlers(): void {
      const input: any = document.getElementById("cameraInput");
      if (!input) return;
      this.input = input;
      input.onchange = this.onPictureChange;
    }

    formatAmount(amount: number, onlyInteger: boolean = true): string {
      return Common.formatAmount(amount, onlyInteger);
    }

    getEventBannerSrc(): string {
      if (this.banners.length == 0) return "";
      const found: any[] = this.banners.filter((i: any) => i.eventId == this.eventId);
      if (found.length == 0) return "";
      return this.smOnly ? found[0].mobile : found[0].desktop;
    }

    getItemProductTypes(): any[] {
      const types: any[] = [];
      if (this.frameProducts.length > 0) {
        types.push({ id: this.productTypes.frame, title: this.$t('searchResults/productPhotoFrame').toString() });
      }
      
      return types;
    }

    getItemClass(type: number, photoId: string): string {
      let result: string = "";
      if (this.smOnly) {
        if (type == 1) {
          result = "list__item " + (!this.photosInCart.includes(photoId) ? "item--unchecked" : "");
        } else {
          result = "list__item ";
        }
      } else {
        if (type == 1) {
          result = "list__item item--large " + (!this.photosInCart.includes(photoId) ? "item--unchecked" : "");
        } else {
          result = "list__item item--large ";
        }
      }
      return result;
    }

    showPackageOffer(productId: string): void {      
      const offers = this.getPackageOffers(productId);

      const isAllPhotos = this.$route.name === 'event-photos';
      
      if (productId === this.photoProduct.productId && offers.length === 0) {
        const itemsInCart: any[] = this.getCartItemsWithProductId(productId);
        if (itemsInCart.length === 1 && this.photosWithMeDiscount > 0) {
          const newProps: any = this.getPhotosWithMeProps();
          if (newProps && !isAllPhotos) offers.push(newProps);
        }
      }

      const routes = ['event-photos', 'search', 'search-from-url'];

      const root: any = this.$root;
      for (let i = 0; i < offers.length; i += 1) {
        setTimeout(() => {
          const routeName = this.$route.name || '';
          if (!routes.includes(routeName)) return;
          root.$addNotification(offers[i]);
        }, 1000);
      }

      if (offers.length > 0) return;

      const sale = this.getProductSale(productId);
      if (sale) {
        setTimeout(() => {
          const routeName = this.$route.name || '';
          if (!routes.includes(routeName)) return;
          root.$addNotification(sale);
        }, 1000);
      }
    }

    showGiftForProduct(product: any): void {
      if (!product) return;
      const gift = this.getGiftForProduct(product);
      if (gift) {
        const productInCart = this.getCartItemsWithProductId(product.productId).length;
        const giftInCart = this.getCartItemsWithProductId(gift.product.productId).length;
        if (productInCart === gift.quantity && giftInCart === 0) {
          const props = this.getGiftProps({ ...gift, trigger: { ...product } });
          const root: any = this.$root;
          if (props) {
            const routes = ['event-photos', 'search', 'search-from-url'];
            setTimeout(() => {
              const routeName = this.$route.name || '';
              if (!routes.includes(routeName)) return;
              root.$addNotification(props);
            }, 1000);
          }
        }
      }
    }

    getPackageOffers(productId: string): Array<any> {
      if (this.packageOffers.length === 0) {
        return [];
      }

      const offers: any[] = [];

      const isAllPhotos = this.$route.name === 'event-photos';

      const foundOffers: any[] = this.packageOffers.filter((i: any) => i.productId === productId);
      if (foundOffers.length === 0) return [];

      const itemsInCart: any[] = this.getCartItemsWithProductId(productId);
      if (itemsInCart.length === 0) return [];

      if (this.photoProduct && productId === this.photoProduct.productId) {
        const offer: any = this.getMatchedOffer(foundOffers, itemsInCart.length);
        if (offer !== null) {
          const props: any = this.getOfferProps(offer);
          if (props) offers.push(props);
          if (!isAllPhotos && offer.activeOffer && this.isFirstSimpleOffer(offer) && this.photosWithMeDiscount > 0) {
            const newProps: any = this.getPhotosWithMeProps();
            if (newProps) offers.push(newProps);
          }
        }
      }

      if (this.frameProducts.length > 0) {
        for (let i = 0; i < this.frameProducts.length; i++) {
          if (this.frameProducts[i].productId !== productId) continue;

          const offer: any = this.getMatchedOffer(foundOffers, itemsInCart.length);
          if (offer !== null) {
            const props: any = this.getOfferProps(offer);
            if (props) offers.push(props);
          }
        }
      }

      if (this.promocodeProduct && productId === this.promocodeProduct.productId) {
        const offer: any = this.getMatchedOffer(foundOffers, itemsInCart.length);
        if (offer !== null) {
          const props: any = this.getOfferProps(offer);
          if (props) offers.push(props);
        }
      }

      return offers;
    }

    getProductSale(productId: string): any {
      const itemsInCart: any[] = this.getCartItemsWithProductId(productId);
      if (itemsInCart.length === 0) return null;

      if (this.photoProduct && productId === this.photoProduct.productId) {
        if (this.photoProduct.priceRule) {
          if (itemsInCart.length === 1) {
            const props: any = this.getSaleProps(this.photoProduct);
            if (props) return props;
          }
        }
        return null;
      }

      if (this.frameProducts.length > 0) {
        for (let i = 0; i < this.frameProducts.length; i++) {
          if (this.frameProducts[i].productId !== productId) continue;
          if (this.frameProducts[i].priceRule) {
            if (itemsInCart.length === 1) {
              const props: any = this.getSaleProps(this.frameProducts[i]);
              if (props) return props;
            }
          }
          return null;
        }
      }

      if (this.promocodeProduct && productId === this.promocodeProduct.productId) {
        if (this.promocodeProduct.priceRule) {
          if (itemsInCart.length === 1) {
            const props: any = this.getSaleProps(this.promocodeProduct);
            if (props) return props;
          }
        }
      }

      return null;
    }

    getMatchedOffer(offers: any[], itemsCount: number): Object | null {
      let offer: any = null;
      let notifyIndexes: number[] = offers.map((i: any) => {
        return i.size;
      });
      const indexes: number[] = [];
      for (let i = 0; i < notifyIndexes.length; i += 1) {
        indexes.push(...[notifyIndexes[i], notifyIndexes[i] + 1]);
      }
      notifyIndexes = indexes.sort().slice(0, indexes.length > 2 ? indexes.length - 2 : indexes.length - 1);
      
      let activeOffer: any = null;
      for (let i = offers.length-1; i >= 0; i--) {
        const hasDependencies = offers[i].dependencies ? offers[i].dependencies.length > 0 : false;
        if (itemsCount > 1 && itemsCount === offers[i].size) {
          activeOffer = { ...offers[i] };
        }
        if (activeOffer && !hasDependencies) {
          offer = { ...offers[i], itemsCount, prevOffer: null };
          if (i >= 1) offer.prevOffer =  { ...offers[i - 1] };
          break;
        }
        if (itemsCount < offers[i].size && !hasDependencies) {
          if (notifyIndexes.includes(itemsCount)) {
            offer = { ...offers[i], itemsCount, prevOffer: null };
            if (i >= 1) offer.prevOffer =  { ...offers[i - 1] };
          }
        }
      }

      if (offer && activeOffer) offer = { ...offer, activeOffer };
      return offer;
    }

    isFirstSimpleOffer(offer: any): boolean {
      return offer.prevOffer ? offer.prevOffer.size === 1 : false;
    }

    getOfferProps(offer: any): any {
      // offer: {
      //   title,  
      //   productId,
      //   productType,
      //   size,
      //   price,
      //   oldPrice,
      //   itemsCount,
      //   activeOffer,
      //   prevOffer,
      // }

      if (!offer) return null;

      const currentPrice = offer.activeOffer ? this.formatAmount(offer.activeOffer.price / offer.itemsCount, false) : this.formatAmount(offer.oldPrice / offer.size, false);
      const newPrice = offer.activeOffer ? this.formatAmount(offer.activeOffer.price / offer.activeOffer.size, false) : this.formatAmount(offer.price / offer.size, false);
      const prevPrice = offer.prevOffer ? this.formatAmount(offer.prevOffer.oldPrice / offer.prevOffer.size, false) : 0;
      let activeTitle = '';
      if (offer.activeOffer) activeTitle = offer.activeOffer.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : offer.activeOffer.title;
      let offerTitle = offer.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : offer.title;
      const props: any = {
        title: offer.activeOffer ? this.$t('packageOffers/received').toString() : this.$t('packageOffers/addMore', [offer.size - offer.itemsCount]).toString(),
        text: offer.activeOffer ? this.$t('packageOffers/newPrice', [activeTitle, offer.activeOffer.size, newPrice, prevPrice]).toString() : this.$t('packageOffers/priceFor', [offerTitle, newPrice, currentPrice]).toString(),
        img: '/img/package.svg',
        discountText: '',
        declineText: offer.activeOffer ? this.$t('packageOffers/thanks').toString() : this.$t('packageOffers/gotIt').toString(),
        confirmText: this.$t('packageOffers/more').toString(),
        confirmType: 'secondary',
        confirmIcon: '',
      };
      const handlers: any = {
        confirm: () => {
          // На страницу с подробностями
          this.onShowOffers();
        },
        decline: () => {
          // Скрыть уведомление насовсем
        },
        dismiss: () => {
          // Скрыть уведомление на 7 суток
        },        
      };
      return { props, ...handlers };
    }

    getPhotosWithMeProps(): any {
      const currentPrice = this.formatAmount(this.photoProduct.amount * this.searchState.photosTotal);
      const newPrice = this.formatAmount(this.photosWithMeProduct.amount);
      const props: any = {
        title: this.$t('packageOffers/addPackage', [this.photosWithMeProduct.title]).toString(),
        text: this.$t('packageOffers/andGet', [newPrice, currentPrice]).toString(),
        img: '/img/all-photos.svg',
        discountText: '',
        declineText: this.$t('packageOffers/notInteresting').toString(),
        confirmText: this.$t('packageOffers/add').toString(),
        confirmType: 'primary',
        confirmIcon: 'shopping-cart',
      };
      const handlers: any = {
        confirm: () => {
          // Добавить Все фото со мной
          this.onAddPhotosWithMe();
        },
        decline: () => {
          // Скрыть уведомление насовсем
        },
        dismiss: () => {
          // Скрыть уведомление на 7 суток
        },        
      };
      return { props, ...handlers };
    }

    getSaleProps(product: any): any {
      if (!product) return null;

      const rule = product.priceRule;
      const discount = rule ? rule.discount : null;

      if (!rule || !discount) return null;

      const currentPrice = this.formatAmount(product.amount);
      const discountedPrice = discount.unit === 'Percentage' ? product.amount - (product.amount * (discount.value / 100)) : product.amount - discount.value;
      const discountInPercents = (product.amount - discountedPrice) / product.amount * 100;
      const newPrice = this.formatAmount(discountedPrice, false);
      const props: any = {
        title: this.$t('packageOffers/fromTo', [rule.minQuantity + 1, rule.maxQuantity, this.getProductTitle(product)]).toString(),
        text: this.$t('packageOffers/fromEvent', [newPrice, currentPrice]).toString(),
        img: this.getProductImage(product),
        discountText: `-${discountInPercents.toFixed(0)}%`,
        declineText: this.$t('packageOffers/gotIt').toString(),
        confirmText: this.$t('packageOffers/more').toString(),
        confirmType: 'secondary',
        confirmIcon: '',
      };
      const handlers: any = {
        confirm: () => {
          // На страницу с подробностями
          this.onShowOffers();
        },
        decline: () => {
          // Скрыть уведомление насовсем
        },
        dismiss: () => {
          // Скрыть уведомление на 7 суток
        },        
      };
      return { props, ...handlers };
    }

    getGiftProps(gift: any): any {
      if (!gift) return null;

      const giftTitle = gift.product.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : gift.product.title;
      const triggerTitle = gift.trigger.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : gift.trigger.title;
      const props: any = {
        title: this.$t('packageOffers/getGift').toString(),
        text: this.$t('packageOffers/productPer', [giftTitle, gift.size, triggerTitle, gift.quantity]).toString(),
        img: this.getProductImage(gift.product),
        discountText: '',
        declineText: this.$t('packageOffers/notInteresting').toString(),
        confirmText: this.$t('packageOffers/get').toString(),
        confirmType: 'primary',
        confirmIcon: 'gift',
      };
      const handlers: any = {
        confirm: () => {
          // На страницу с подробностями
          this.onShowOffers();
        },
        decline: () => {
          // Скрыть уведомление насовсем
        },
        dismiss: () => {
          // Скрыть уведомление на 7 суток
        },        
      };
      return { props, ...handlers };
    }

    getProductImage(product: any): string {
      if (!product) return '';
      if (product.productType === this.productTypes.photos_with_me) return '/img/all-photos.svg';
      if (product.productType === this.productTypes.photosWithFrame) return '/img/all-photos-in-frame.svg';
      if (product.productType === this.productTypes.photo) return '/img/single-photo.svg';
      if (product.productType === this.productTypes.frame) return '/img/photo-in-frame.svg';
      if (product.productType === this.productTypes.promocode) return '/img/promocode.svg';
      return '';
    }

    getProductTitle(product: any): string {
      const type = product.productType;
      if (!type) return '';

      if (type === this.productTypes.photos_with_me) {
        return this.$t('productPreview/productAllPhotos').toString();
      } else if (type === this.productTypes.photosWithFrame) {
        return product.title;
      } else if (type === this.productTypes.photo) {
        return this.$t('productPreview/productDigitalPhoto').toString();
      } else if (type === this.productTypes.promocode) {
        return product.title;
      } else {
        return this.$t('searchResults/productPhotoFrame').toString();
      }
    }

    getProductDescription(offer: any): string {
      if (!offer) return '';
      if (offer.productType === this.productTypes.photos_with_me) return this.$t('packageOffers/photosWithMe').toString();
      if (offer.productType === this.productTypes.photosWithFrame) return this.$t('packageOffers/photosWithFrame').toString();
      if (offer.productType === this.productTypes.photo) return this.$t('packageOffers/anyPhotos', [offer.size]).toString();
      if (offer.productType === this.productTypes.frame) return '';
      if (offer.productType === this.productTypes.promocode) return '';
      return '';
    }

    getPackageOffersOptions() {
      const options = [];
      
      const products = this.getOfferProducts();
      if (products) options.push(products);
      
      const packages = this.getOfferPackages();
      if (packages) options.push(packages);
      
      const gifts = this.getOfferGifts();
      if (gifts) options.push(gifts);

      return options;
    }

    getOfferProducts(): any {
      const result = {
        title: 'packageOffers/products',
        type: OfferTypes.product,
      };

      const items: any[] = [];
      const isAllPhotos = this.$route.name === 'event-photos';

      if (this.photosWithMeDiscount > 0) {
        const inCart = this.isProductIdInCart(this.photosWithMeProduct.productId);
        items.push({
          type: OfferTypes.product,
          state: inCart ? OfferStates.inCart : OfferStates.initial,
          title: this.photosWithMeProduct.title,
          subtitle: this.$t('packageOffers/photosWithMe').toString(),
          price: this.formatAmount(this.photosWithMeProduct.amount),
          image: this.getProductImage(this.photosWithMeProduct),
          action: inCart ? 
            { icon: 'check-bold', text: this.$t('packageOffers/inCart').toString() } :
            { icon: 'shopping-cart', text: this.$t('packageOffers/add').toString(), func: () => this.onAddPhotosWithMe() },
          isAllPhotos,
        });
      }

      if (this.photosWithFrameDiscount > 0) {
        const inCart = this.isProductIdInCart(this.photosWithFrameProduct.productId);
        items.push({
          type: OfferTypes.product,
          state: inCart ? OfferStates.inCart : OfferStates.initial,
            title: this.photosWithFrameProduct.title,
            subtitle: this.$t('packageOffers/photosWithFrame').toString(),
            price: this.formatAmount(this.photosWithFrameProduct.amount),
            image: this.getProductImage(this.photosWithFrameProduct),
            action: inCart ? 
              { icon: 'check-bold', text: this.$t('packageOffers/inCart').toString() } : 
              { icon: 'shopping-cart', text: this.$t('packageOffers/add').toString(), func: () => this.onPhotosWithFrameAdd() },
            isAllPhotos,
        });
      }

      return items.length > 0 ? { ...result, items } : null;
    }

    getOfferPackages(): any {
      const result = {
        title: 'packageOffers/packages',
        type: OfferTypes.package,
      };

      const items: any[] = [];

      for (let i = 0; i < this.packageOffers.length; i += 1) {
        const offer = this.packageOffers[i];
        if (offer.dependencies ? offer.dependencies.length > 0 : false) continue;
        if (offer.productType === this.productTypes.photos_with_me) continue;
        if (offer.size === 1) continue;
        if (offer.price === 0) continue;
        const productCount = this.getCartItemsWithProductId(offer.productId);
        const title = offer.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : offer.title;
        items.push({
          type: OfferTypes.package,
          state: OfferStates.initial,
          title: this.$t('packageOffers/productQty', [title, offer.size]).toString(),
          subtitle: this.getProductDescription(offer),
          price: this.formatAmount(offer.price),
          oldPrice: offer.oldPrice ? this.formatAmount(offer.oldPrice) : 0,
          image: '/img/package.svg',
          progress: { value: productCount.length, max: offer.size, },
        });
      }

      for (let k = 0; k < this.catalogProducts.length; k += 1) {
        const item = this.getSalePackage(this.catalogProducts[k]);
        if (item) items.push(item);
      }

      for (let i = 0; i < this.packageOffers.length; i += 1) {
        const offer = this.packageOffers[i];
        if (offer.dependencies ? offer.dependencies.length === 0 : true) continue;
        if (offer.productType === this.productTypes.photos_with_me) continue;
        if (offer.size === 1) continue;
        if (offer.price === 0) continue;
        const productCount = this.getCartItemsWithProductId(offer.productId);
        const dependencyProducts = this.catalogProducts.filter((item: any) => item.productId === offer.dependencies[0].productId);
        if (dependencyProducts.length === 0) continue;
        const quantity = offer.dependencies[0].quantity;
        const dependencyTitle = dependencyProducts[0].productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : dependencyProducts[0].title;
        const dependency = `<b>${dependencyTitle}${quantity > 0 ? ' x ' + quantity + '&nbsp;' + this.$t('saleSnackbar/qty') : ''}</b>`;
        const offerTitle = offer.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : offer.title;
        items.push({
          type: OfferTypes.package,
          state: OfferStates.initial,
          title: this.$t('packageOffers/productQty', [offerTitle, offer.size]).toString(),
          subtitle: this.getProductDescription(offer),
          price: this.formatAmount(offer.price),
          oldPrice: offer.oldPrice ? this.formatAmount(offer.oldPrice) : 0,
          image: '/img/package.svg',
          progress: { value: productCount.length, max: offer.size, },
          warning: dependency,
        });
      }

      return items.length > 0 ? { ...result, items } : null;
    }

    getOfferGifts(): any {
      const result = {
        title: 'packageOffers/gifts',
        type: OfferTypes.gift,
      };

      const items: any[] = [];

      for (let k = 0; k < this.catalogProducts.length; k += 1) {
        const item = this.getGiftForProduct(this.catalogProducts[k]);
        if (item) {
          const gift = this.getGiftItem({ ...item, trigger: { ...this.catalogProducts[k] } });
          if (gift) items.push(gift);
        }
      }

      return items.length > 0 ? { ...result, items } : null;
    }

    getGiftForProduct(product: any): any {
      if (!product) return null;
      const candidates: any[] = [...product.candidates];
      for (let i = 0; i < candidates.length; i += 1) {
        const gifts: any[] = candidates[i].packageOffers.filter((r: any) => r.price === 0);
        if (gifts.length > 0) {
          const quantity = gifts[0].dependencies[0] ? gifts[0].dependencies[0].quantity : 0;
          if (quantity) {
            return { product: { ...candidates[i] }, quantity, size: gifts[0].size };
          }
          return null;
        }
      }
      return null;
    }

    getGiftLabelPhotosWithMe(gift: any): string {
      if (!gift) return '';
      const title = gift.product.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : gift.product.title;
      return this.$t('packageOffers/productAsGift', [title, gift.size]).toString();
    }

    getSalePackage(product: any): any {
      if (!product) return null;

      const rule = product.priceRule;
      const discount = rule ? rule.discount : null;

      if (!rule || !discount) return null;

      const currentPrice = this.formatAmount(product.amount);
      const discountedPrice = discount.unit === 'Percentage' ? product.amount - (product.amount * (discount.value / 100)) : product.amount - discount.value;
      const newPrice = this.formatAmount(discountedPrice, false);
      const props: any = {
        type: OfferTypes.package,
        state: OfferStates.initial,
        title: `${this.getProductTitle(product)}`,
        subtitle: this.$t('packageOffers/qtyRange', [rule.minQuantity + 1, rule.maxQuantity]).toString(),
        price: newPrice,
        oldPrice: currentPrice,
        image: this.getProductImage(product),
      };
      return props;
    }

    getGiftItem(gift: any): any {
      if (!gift) return null;

      const productCount = this.getCartItemsWithProductId(gift.product.productId).length;
      const triggerCount = this.getCartItemsWithProductId(gift.trigger.productId).length;
      let state = OfferStates.initial;
      if (productCount >= gift.size && triggerCount >= gift.quantity) state = OfferStates.inCart;
      if (productCount < gift.size && triggerCount >= gift.quantity) state = OfferStates.giftReady;
      
      let action = null;
      if (state === OfferStates.giftReady) action = { icon: 'gift', text: this.$t('packageOffers/get').toString(), func: () => this.addGift(gift) };
      if (state === OfferStates.inCart) action = { icon: 'check-bold', text: this.$t('packageOffers/giftInCart').toString() };

      const isAllPhotos = this.$route.name === 'event-photos';

      const giftTitle = gift.product.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : gift.product.title;
      const triggerTitle = gift.trigger.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : gift.trigger.title;
      const props: any = {
        type: OfferTypes.gift,
        state,
        title: this.$t('packageOffers/productQty', [giftTitle, gift.size]).toString(),
        subtitle: this.$t('packageOffers/giftFor', [triggerTitle, gift.quantity]).toString(),
        image: this.getProductImage(gift.product),
        action,
        isAllPhotos,
      };
      return props;
    }

    sortOffers(a: any, b: any): number {
      if (a.size < b.size) return -1;
      if (a.size > b.size) return 1;
      return 0;
    }

    async isTagged(): Promise<boolean> {
      if (this.eventsState.event === null) return false;
      return this.eventsState.event.searchingPhotos.byTag === true;
    }

    isEventProductsInCart(): boolean {
      if (this.$route.name !== "event-photos") {
        if (this.isProductInCart(this.productTypes.photos_with_me) ||
            this.isProductInCart(this.productTypes.photosWithFrame) ||
            this.isProductInCart(this.productTypes.photo) ||
            this.isProductInCart(this.productTypes.frame)) {
          return true;
        }
      } else {
        if (this.isProductIdInCart(this.photoProduct.productId)) {
          return true;
        }
      }
      return false;
    }

    getCatalogProducts(type: string): any[] {
      const products: any[] = Object.assign([], this.catalogProducts);
      if (products.length === 0) {
        return [];
      } else {      
        const found: any[] = products.filter((r: any) => r.productType === type);
        if (found.length > 0) {
          for (let i = 0; i < found.length; i++) {
            if (type === this.productTypes.frame) found[i].title = this.$t('searchResults/productPhotoFrame').toString();
          }
        }
        return found;
      }
    }

    isProductEnabled(type: string): boolean {
      if (this.catalogProducts.length === 0) {
        return false;
      } else {      
        return this.catalogProducts.filter((r: any) => r.productType === type).length > 0;
      }
    }

    isProductInCart(type: string): boolean {

      if (type == "") return false;

      if (this.cartState.itemsCount == 0) {
        return false;
      } else {      
        
        const items: any[] = this.cartState.cartItems;
        for (let i of items) {
          if (!i.product) continue;
          if (!i.product.productId) continue;
          if (!i.catalog) continue;
          if (!i.catalog.catalogId) continue;
          if (i.catalog.catalogId != this.eventId) continue;
          
          if (i.additionalAttributes) {
            const additionalAttributes: any[] = i.additionalAttributes;

            const productType: string = i.product.productType;
            if (productType.length == 0) continue;
            if (productType != type) continue;

            const byFace: string = Common.getAttribute(additionalAttributes, "byFace");
            if (byFace.length == 0) continue;
            if (byFace != this.byFace.toString()) continue;

            if (!this.byFace) {
              const runnerNum: string = Common.getAttribute(additionalAttributes, "runnerNum");
              if (runnerNum.length == 0) continue;
              if (runnerNum != this.runnerNum) continue;
            } else {
              const selfieId: string = Common.getAttribute(additionalAttributes, "selfieId");
              if (selfieId.length == 0) continue;
              if (selfieId != this.selfieId) continue;
              
              const personId: string = Common.getAttribute(additionalAttributes, "personId");
              if (personId.length == 0) continue;
              if (personId != this.personId) continue;
            }

            return true;
          } 
        }
        return false;
      }
    }

    isProductIdInCart(productId: string, currentSearchOnly: boolean = false): boolean {
      if (productId === '') return false;

      if (this.cartState.itemsCount === 0) return false;

      const items: any[] = this.cartState.cartItems;
      for (let i of items) {
        if (!i.product) continue;
        if (!i.product.productId) continue;
        if (i.product.productId !== productId) continue;
        if (currentSearchOnly) {
          const additionalAttributes: any[] = i.additionalAttributes;
          const byFace: string = Common.getAttribute(additionalAttributes, 'byFace');
          if (byFace.length === 0) continue;
          if (byFace !== this.byFace.toString()) continue;

          if (!this.byFace) {
            const runnerNum: string = Common.getAttribute(additionalAttributes, 'runnerNum');
            if (runnerNum.length === 0) continue;
            if (runnerNum !== this.runnerNum) continue;
          } else {
            const selfieId: string = Common.getAttribute(additionalAttributes, 'selfieId');
            if (selfieId.length === 0) continue;
            if (selfieId !== this.selfieId) continue;
            
            const personId: string = Common.getAttribute(additionalAttributes, 'personId');
            if (personId.length === 0) continue;
            if (personId !== this.personId) continue;
          }
        }
        return true;
      }

      return false;
    }

    getCartItemsWithProductId(productId: string): any[] {
      let result: any[] = [];

      const items: any[] = [ ...this.cartState.cartItems ];
      if (items.length == 0) {
        return [];
      } 

      for (let i = 0; i < items.length; i++) {
        if (!items[i].product) continue;
        if (items[i].product.productId == productId) {
          result.push(items[i]);
        } 
      }
      return result;
    }

    async getPhotosForAttributes(): Promise<any[]> {
      
      const payload = {
        eventId: this.eventId,
        token: this.getCurrentSearchToken(),
      };
      await this.searchState.searchByTagAll(payload);
      if (this.searchState.isTokenExpired) {
        const result: any = await this.getNewSearchToken({
          eventId: this.eventId,
          payload: {
            startNumber: !this.byFace ? this.runnerNum : undefined,
            personId: this.byFace ? this.personId : undefined,
          },
        });
        if (result) {
          payload.token = result.value;
          await this.searchState.searchByTagAll(payload);
          if (this.searchState.searchError) return [];
          this.searchToken = { ...result };
        } else {
          return [];
        }
      }

      const photos = this.searchState.photosAll;
      const selected = [];

      for (let i = 0; i < photos.length; i++) {
        selected.push({ key: "PhotoId", value: photos[i].photoId });
      }

      for (let i = 0; i < this.itsMePhotos.length; i++) {
        if (this.itsMePhotos[i]) selected.push({ key: "PhotoId", value: this.itsMePhotos[i] });
      }

      return selected;
    }

    getPhotosWithMeAttributes(): any[] {
      const attributes: any[] = [];

      attributes.push({ key: "byFace", value: this.byFace.toString() });

      if (!this.byFace) {
        attributes.push({ key: "runnerNum", value: this.runnerNum });
        attributes.push({ key: "StartNumber", value: this.runnerNum });
      } else {
        attributes.push({ key: "selfieId", value: this.selfieId });
        attributes.push({ key: "personId", value: this.personId });
        attributes.push({ key: "PersonId", value: this.personId });
      }

      return attributes;
    }

    getPhotoAttributes(): any[] {
      const attributes: any[] = [];

      attributes.push({ key: "byFace", value: this.byFace.toString() });

      if (!this.byFace) {
        attributes.push({ key: "runnerNum", value: this.runnerNum || "0" });
        attributes.push({ key: "StartNumber", value: this.runnerNum || "0" });
      } else {
        attributes.push({ key: "selfieId", value: this.selfieId });
        attributes.push({ key: "personId", value: this.personId });
        attributes.push({ key: "PersonId", value: this.personId });
      }

      return attributes;
    }

    getFrameAttributes(): any[] {
      const attributes: any[] = [];

      attributes.push({ key: "byFace", value: this.byFace.toString() });

      if (!this.byFace) {
        attributes.push({ key: "runnerNum", value: this.runnerNum || "0" });
        attributes.push({ key: "StartNumber", value: this.runnerNum || "0" });
      } else {
        attributes.push({ key: "selfieId", value: this.selfieId });
        attributes.push({ key: "personId", value: this.personId });
        attributes.push({ key: "PersonId", value: this.personId });
      }

      return attributes;
    }

    setSelectedFrameValues(): void {
      this.selectedFrameValues = { };

      const frameValues: any = { ...this.runnerFrameValues };

      if (frameValues) {
        this.selectedFrameValues['competitorId'] = frameValues.competitorId || "";
        this.selectedFrameValues['surname'] = frameValues.surname || "";
        this.selectedFrameValues['firstName'] = frameValues.firstName || "";
        this.selectedFrameValues['time'] = frameValues.time || "";
        this.selectedFrameValues['distance'] = frameValues.distance || "";
      }      
    }

    getValuesForFrame(selectedPhotoId: string, selectedFrameId: string): any[] {
      const values: any[] = [
        { key: "PhotoId", value: selectedPhotoId },
        { key: "FrameId", value: selectedFrameId },
      ];
      
      if (this.selectedFrameValues) {
        if (this.selectedFrameValues.competitorId) {
          values.push({ key: 'CompetitorId', value: this.selectedFrameValues.competitorId });
        }
      } else if (this.runnerNum) {
        values.push({ key: 'ParticipantNumber', value: this.runnerNum !== "0" ? this.runnerNum : "-" });
      }

      return values;
    }

    getValuesForPhotosWithFrame(): any[] {
      const values: any[] = [
        { key: "FrameId", value: Common.getAttribute(this.photosWithFrameProduct.attributes, 'FrameId') },
      ];
      
      if (this.selectedFrameValues) {
        if (this.selectedFrameValues.competitorId) {
          values.push({ key: 'CompetitorId', value: this.selectedFrameValues.competitorId });
        }
      } else if (this.runnerNum) {
        values.push({ key: 'ParticipantNumber', value: this.runnerNum !== "0" ? this.runnerNum : "-" });
      }

      return values;
    }

    getItemPhotos(item: any): string[] {
      if (!item) return [];
      
      const type: string = item.product ? item.product.productType : '';
      if (type == this.productTypes.frame) return [];

      let photoIds: string[] = [];
      if (type == this.productTypes.photo) {
        photoIds = [ item.product.photo.photoId ];  
      } else if (type == this.productTypes.photos_with_me) {
        photoIds = item.product.photos.map((i: any) => {
          return i.photoId;
        });
      }

      return photoIds;
    }

    getPhotosForPhotoFrame(gift: any = null): any[] {
      if (gift && gift.trigger) {
        const photos: any[] = [];
        const cartItems: any[] = this.getCartItemsWithProductId(gift.trigger.productId);
        for (let i = 0; i < cartItems.length; i += 1) {
          if (gift.trigger.productType === this.productTypes.photo) {
            photos.push({ ...cartItems[i].product.photo });
          } else {
            photos.push(...cartItems[i].product.photos);
          }
        }        
        return photos;
      }
      return [...this.photos, ...this.findMoreResults];
    }

    getPageNumber(perPage: number = 0): number {
      const p: any = new URLSearchParams(window.location.search);
      if (p.size === 0) return 1;
      const page: any = p.get('p');
      if (!page) return 1;
      if (!this.isValidPage(page, perPage)) return -1;
      return parseInt(page);
    }

    isValidPage(val: any, perPage: number = 0): boolean {
      const value = parseInt(val);
      if (!isFinite(value)) return false;
      if (value < 1 || value > (perPage ? this.getTotalPagesFor(perPage) : this.totalPages)) return false;
      return true;
    }

    getTotalPagesFor(perPage: number): number {
      if (!perPage) return 0;
      return Math.ceil(this.searchState.photosTotal / perPage);
    }

    hasPhotoIdInURL(): boolean {
      const p = new URLSearchParams(window.location.search);
      const id = p.get('id');
      return id !== null && id !== '';
    }

    getPhotoIdFromURL(): string {
      const p = new URLSearchParams(window.location.search);
      return p.get('id') || '';
    }

    getIsMobileFromURL(): boolean | null {
      const p = new URLSearchParams(window.location.search);
      const m = p.get('m');
      if (m) return m === 'true';

      return null;
    }

    isPhotoAddingToCart(photoId: string): boolean {
      const item = this.photosAddingToCart.find((i: any) => i.id === photoId);
      if (item) {
        return item.adding === true;
      }
      return false;
    }

    getCountAddingToCart(): number {
      return this.photosAddingToCart.filter((i: any) => i.adding === true).length;
    }

    setAddingToCart(photoId: string, value: boolean): void {
      if (!photoId) return;

      const index = this.photosAddingToCart.findIndex((i: any) => i.id === photoId);
      if (index >= 0) {
        this.photosAddingToCart[index].adding = value;
      } else {
        this.photosAddingToCart.push({ id: photoId, adding: value });
      }
    }

    getNextPhotoForAdding(): string {
      for (let i = 0; i < this.photosAddingToCart.length; i += 1) {
        if (this.photosAddingToCart[i].adding) {
          return this.photosAddingToCart[i].id;
        }
      }
      return "";
    }

    scrollToMorePhotos() {
      const el = document.getElementById('searchPhotosMore');
      if (!el) return;
      el.scrollIntoView({ behavior: 'smooth' });
    }

    disableVerticalScroll(disable: boolean) {
      if (disable) {
        document.documentElement.setAttribute('style', 'overflow-y: hidden !important;');
        document.body.style.overflowY = 'hidden';
      } else {
        document.documentElement.setAttribute('style', '');
        document.body.style.overflowY = '';
      }
    }

    getSingleFrameAmount(frameId: string): number {
      for (let i = 0; i < this.frameProducts.length; i += 1) {
        const id = Common.getAttribute(this.frameProducts[i].attributes, 'FrameId');
        if (id === frameId) return this.frameProducts[i].amount;
      }
      return 0;
    }

    getFramesInCart(): any[] {
      const result = [];
      for (let i = 0; i < this.frameProducts.length; i += 1) {
        const items = this.getCartItemsWithProductId(this.frameProducts[i].productId);
        result.push(...items);
      }
      if (this.photosWithFrameProduct && this.isProductIdInCart(this.photosWithFrameProduct.productId, true)) {
        const items = this.getCartItemsWithProductId(this.photosWithFrameProduct.productId);
        result.push(...items);
      }
      return result;
    }

    async onBackToSearch(): Promise<void> {
      
      await this.cleanup();

      const event: any = this.eventsState.event;

      await this.$router.push({
        name: "event",
        params: {
          id: event.externalEventId,
        }
      });
    }

    async onShowMyPhotos(): Promise<void> {
      if (this.$route.name === "search-from-url") 
         return;
      
      await this.cleanup();

      const event: any = this.eventsState.event;

      const link: string = this.settingsState.myPhotosLink;
      if (link) {
        location.pathname = link;
        return;
      }

      await this.$router.push({
        name: "event",
        params: {
          id: event.externalEventId,
        }
      });
    }

    async onShowAllPhotos(): Promise<void> {
      if (this.$route.name === "event-photos") return;
      
      await this.cleanup();

      const event: any = this.eventsState.event;

      await this.$router.push({
        name: "event-photos",
        params: {
          id: event.externalEventId,
        }
      });
    }

    async onShowOffers(): Promise<void> {
      this.packageOffersOptions = this.getPackageOffersOptions();
      this.disableVerticalScroll(true);
      this.showPackageOffers = true;
      this.settingsState.reachGoal('special_offers_details');
    }

    async onOptionSelected(payload: { photoId: string, productIndex: number }): Promise<void> {
      this.showPhotoFrameFromItsMe = false;

      const types: any[] = this.getItemProductTypes();
      if (types.length === 0) return;

      if (types[payload.productIndex].id === this.productTypes.frame) {
        this.selectedPhotoId = payload.photoId;
        this.autoShowPhotoId = "";
        this.autoShowPhotoIndex = -1;
        await this.onAddFrame();
      }
    }

    async onOptionSelectedFromItsMe(payload: { photoId: string, productIndex: number }): Promise<void> {
      this.showPhotoFrameFromItsMe = true;

      const types: any[] = this.getItemProductTypes();
      if (types.length === 0) return;

      if (types[payload.productIndex].id === this.productTypes.frame) {
        this.selectedPhotoId = payload.photoId;
        this.autoShowPhotoId = "";
        this.autoShowPhotoIndex = -1;
        await this.onAddFrame();
      }
    }

    async onPreviewPageChanged(index: number): Promise<void> {
      this.previewPhotos = await this.getPreviewPhotos(index);
      this.currentIndex = index;
      this.previewDialogKey = Math.random().toString();
    }

    async onBeforeCart(): Promise<void> {
      if (this.smOnly && this.isButtonInvisible && !this.isProductInCart(this.productTypes.photos_with_me)) {
        await this.onAddPhotosWithMe();
        return;
      }

      if (this.$route.name === 'event-photos') {
        await this.onGoToCart();
        return;
      }

      if (this.photosWithFrameProduct && !this.isProductIdInCart(this.photosWithFrameProduct.productId, true)) {
        this.disableVerticalScroll(true);
        const frameId = Common.getAttribute(this.photosWithFrameProduct.attributes, 'FrameId');
        const singleFrameAmount = this.getSingleFrameAmount(frameId) || 0;
        this.photosWithFrameOptions = {
          items: [
            {
              singleFrameAmount,
              ...this.photosWithFrameProduct,
            },
            ...this.frameProducts,
          ],
          photosCount: this.searchState.photosTotal,
          alreadyInCart: this.isProductIdInCart(this.photosWithFrameProduct.productId, true),
        };
        this.photosWithFrameDialog = true;
        return;
      }

      await this.onGoToCart();
    }

    async onGoToCart(): Promise<void> {
      await this.cleanup();      

      this.$store.state.history = [];
      await this.$router.push({ name: "cart" });
    }

    async onAddToCart(index: number): Promise<void> {
      this.selectedPhotoId = this.photos[index].photoId;
      this.selectedPhotoUrl = this.photos[index].resources.preview;
      await this.onSelectedPhoto(this.selectedPhotoId);
    }
    
    async onPreviewAddToCart(index: number): Promise<void> {
      this.selectedPhotoId = this.previewPhotos[index].photoId;
      this.selectedPhotoUrl = this.previewPhotos[index].resources.preview;
      await this.onSelectedPhoto(this.selectedPhotoId);      
    }

    async onSliderAddCart(payload: { productId: string }): Promise<void> {
      this.selectedPhotoId = "";
      this.selectedFrameProductId = "";
      this.autoShowPhotoId = "";
      this.autoShowPhotoIndex = -1;
      this.showPhotoFrameFromItsMe = false;
      await this.showFramesPage(payload.productId);
    }

    async onMoreSearch(payload: any): Promise<void> {
      if (payload.startNumber) {
        this.moreRunnerNum = payload.startNumber;
        this.moreSearching = true;

        const token: any = this.getCurrentSearchToken();
        const payloadForToken = {
          eventId: this.eventId,
          token,
          payload: { startNumber: payload.startNumber },
        };
        let tokenResult = await this.searchState.getToken(payloadForToken);
        if (tokenResult.status !== 200) {
          if (this.searchState.isTokenExpired && this.$props.recentSearch_) {
            const result: any = await this.getNewSearchToken({
              eventId: this.eventId,
              payload: {
                startNumber: this.$props.recentSearch_?.search.startNumber,
              },
            });
            if (result) {
              payloadForToken.token = result.value;
              tokenResult = await this.searchState.getToken(payloadForToken);
              if (tokenResult.status !== 200) return;
            }
          }
          return;
        }

        const params: any = {
          eventId: this.eventId,
          token: this.searchState.searchToken ? this.searchState.searchToken.value : '',
          pagination: { offset: 0, count: this.photosPerPage },
          additional: true,
        };

        await this.searchState.searchByTag(params);
        if (this.searchState.searchError) {
          this.showNotFoundMore = false;
          this.showFindMoreResults = false;
          this.showErrorMore = true;
          this.moreSearching = false;
          await this.recreateMasonry();
          return;
        }
        
        if (this.searchState.additionalPhotos.length > 0) {
          this.findMoreResults = [...this.searchState.additionalPhotos];
          this.showNotFoundMore = false;
          this.showErrorMore = false;
          this.moreSearching = false;
          this.showFindMoreResults = true;
        } else {
          this.showNotFoundMore = true;
          this.showErrorMore = false;
          this.moreSearching = false;
        }
        await this.recreateMasonry();
        return;
      }

      if (payload.faceImage) {
        this.moreRunnerNum = payload.startNumber;
        this.moreFaceImage = payload.faceImage;
        await this.onSearchByFace();
      }
    }

    async onNewMoreSearch(): Promise<void> {
      this.moreRunnerNum = '';
      this.moreFaceImage = '';
      this.showFindMoreResults = false;
      this.showNotFoundMore = false;
      this.showErrorMore = false;
      this.moreSearching = false;
      this.findMoreResults = [];
      this.itsMePhotos = [];
    }

    async onMorePreview(index: number): Promise<void> {
      await this.onItsMeImageClick(index);
    }

    async onItsMe(item: any): Promise<void> {
      const personId = this.searchState.persons[0].personId || '';
      const setItIsMe = !this.itsMePhotos.includes(item.photoId);
      const payload = {
        photoId: item.photoId,
        itIsMe: setItIsMe,
        photoTagId: uuidv4(),
        name: this.moreByPhoto ? 'personId' : 'startNumber',
        value: this.moreByPhoto ? personId : this.moreRunnerNum,
      };
      await this.searchState.setItIsMe(payload);

      if (!this.searchState.searchError) {
        if (!this.itsMePhotos.includes(item.photoId)) {
          this.itsMePhotos = [...this.itsMePhotos, item.photoId];
        } else {
          if (!setItIsMe) {
            const index = this.itsMePhotos.indexOf(item.photoId);
            if (index >= 0) Vue.set(this.itsMePhotos, index, '');
          }
        }
      }
    }

    async onAddPhotosWithMe(isGift: boolean = false): Promise<void> {
      if (this.isAddingPhotosWithMe) return;

      this.isAddingPhotosWithMe = true;

      if (!this.cartState.cart || this.cartState.cartId == "") {
        const cartId: string = uuidv4();
        await this.cartState.createCart({ cartId: cartId });
      }
      
      const attributes = await this.getPhotosForAttributes();
      if (attributes.length === 0) {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString());
        this.isAddingPhotosWithMe = false;
        return;
      }
      const payload = { 
        cartId: this.cartState.cartId,
        searchToken: this.getCurrentSearchToken(),
        item: {
          catalogId: this.eventId,
          productId: this.photosWithMeProduct.productId,
          attributes: attributes,
          additionalAttributes: this.getPhotosWithMeAttributes(),
        }
      };
      
      await this.cartState.addItem(payload);
      if (this.cartState.isCartError) {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
        this.isAddingPhotosWithMe = false;
        return;
      }
      
      await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      await this.cartState.pushEcommerceAdd();
      this.isAddingPhotosWithMe = false;

      this.showGiftForProduct(this.photosWithMeProduct);
      this.showPackageOffer(this.photosWithMeProduct.productId);
      
      if (isGift) this.showSnackbar = true;

      this.settingsState.reachGoal('add_to_cart_all_photos');

      await this.updatePhotosInCart();
    }

    async onAddFrame(): Promise<void> {
      this.selectedFrameProductId = "";
      await this.showFramesPage();
    }

    getCurrentSearchToken(): string {
      const newToken: string = this.searchToken ? this.searchToken.value || '' : '';
      return newToken || (this.$props.searchToken_ ? (this.$props.searchToken_.value || this.$props.searchToken_.token || '') : '');
    }

    async getNewSearchToken(payload: any): Promise<any> {
      const tokenResult = await this.searchState.getToken(payload);
      if (tokenResult.status !== 200) return null;
      return this.searchState.searchToken;
    }

    async showFramesPage(productId: string = "", gift: any = null): Promise<void> {

      const fromItsMe = this.previewDialog && this.previewItsMe;
      if (fromItsMe || this.showPhotoFrameFromItsMe) {
        const found = this.findMoreResults.filter((r: any) => r.photoId == this.selectedPhotoId);
        if (found.length > 0) {
          this.selectedPhoto = found[0];
        } else {
          this.selectedPhoto = this.findMoreResults[0];
        }
        this.showPhotoFrameFromItsMe = true;
      } else {
        const found = this.photos.filter((r: any) => r.photoId == this.selectedPhotoId);
        if (found.length > 0) {
          this.selectedPhoto = found[0];
        } else {
          this.selectedPhoto = this.photos[0];
        }
        this.showPhotoFrameFromItsMe = false;
      }

      const isPhotosWithFrame = this.photosWithFrameProduct ? this.photosWithFrameProduct.productId === productId : false;
      this.selectedFrameProductId = isPhotosWithFrame ? this.frameProducts[0].productId : productId;
      this.allPhotosMode = isPhotosWithFrame === true;
      this.photoFramesInCart = this.getFramesInCart();
      this.photosForFrame = this.getPhotosForPhotoFrame(gift);
      this.photoFrameGift = gift;

      if (this.previewDialog) this.previewDialog = false;
      if (this.photosWithFrameDialog) {
        this.photosWithFrameDialog = false;
        this.disableVerticalScroll(false);
      }

      this.photoFrameDialog = true;
    }

    async onSelectedPhoto(photoId: string): Promise<void> {
      if (!photoId) return;
      
      this.setAddingToCart(photoId, true);
      if (this.getCountAddingToCart() === 1) {
        await this.checkForAddingPhotos();
      }
    }

    async checkForAddingPhotos(): Promise<void> {
      const photoId = this.getNextPhotoForAdding();
      if (!photoId) return;

      await this.addSinglePhotoToCart(photoId);

      setTimeout(async () => { await this.checkForAddingPhotos(); }, 100);
    }

    async addSinglePhotoToCart(photoId: string): Promise<void> {
      if (!photoId) return;

      if (!this.cartState.cart || this.cartState.cartId == "") {
        const cartId: string = uuidv4();
        await this.cartState.createCart({ cartId: cartId });
      }
      const payload = { 
        cartId: this.cartState.cartId,
        searchToken: this.getCurrentSearchToken(),
        item: {
          catalogId: this.eventId,
          productId: this.photoProduct.productId,
          attributes: [ 
            { key: "PhotoId", value: photoId },
          ],
          additionalAttributes: this.getPhotoAttributes(),
        }
      };
      
      await this.cartState.addItem(payload);
      if (this.cartState.isCartError) {
        if (this.cartState.responseStatus == 409) {
          this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
        } else {
          this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
        }
        this.setAddingToCart(photoId, false);
        return;
      }
            
      await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      await this.cartState.pushEcommerceAdd();
      
      this.setAddingToCart(photoId, false);
      
      this.showGiftForProduct(this.photoProduct);
      this.showPackageOffer(this.photoProduct.productId);

      if (!this.photosInCart.includes(photoId)) {
        this.photosInCart.push(photoId)
      }

      this.settingsState.reachGoal('add_to_cart_single_photo');
    }

    async addFrameToCart(params: {
      selectedPhoto: string,
      selectedPhotoUrl: string,
      selectedFrameId: string,
      selectedFrameProductId: string,
      autoShowPhotoId: string,
    }): Promise<void> {
      if (this.isAddingFrame) return;

      this.isAddingFrame = true;

      if (!this.cartState.cart || this.cartState.cartId == "") {
        const cartId: string = uuidv4();
        await this.cartState.createCart({ cartId: cartId });
      }
      
      const payload = { 
        cartId: this.cartState.cartId,
        searchToken: this.getCurrentSearchToken(),
        item: {
          catalogId: this.eventId,
          productId: params.selectedFrameProductId,
          attributes: this.getValuesForFrame(params.selectedPhoto, params.selectedFrameId),
          additionalAttributes: this.getFrameAttributes(),
        }
      };
      
      await this.cartState.addItem(payload);
      if (this.cartState.isCartError) {
        if (this.cartState.responseStatus == 409) {
          this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
        } else {
          this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
        }
        this.isAddingFrame = false;
        return;
      }

      await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      await this.cartState.pushEcommerceAdd();
      this.photoFramesInCart = this.getFramesInCart();
      this.isAddingFrame = false;

      this.showPackageOffer(params.selectedFrameProductId);
    }

    async onPhotoFrameAdd(payload: {
      selectedPhoto: string,
      selectedPhotoUrl: string,
      selectedFrameId: string,
      selectedFrameProductId: string,
      autoShowPhotoId: string,
    }): Promise<void> {
      if (payload.selectedPhoto !== undefined) {
        if (payload.selectedFrameId !== undefined) {
          await this.addFrameToCart(payload);
          if (this.cartState.isCartError) return;

          this.selectedFrameId = payload.selectedFrameId;
          this.selectedFrameProductId = payload.selectedFrameProductId;
          this.selectedPhotoId = payload.selectedPhoto;
          this.selectedPhotoUrl = payload.selectedPhotoUrl;
        }
      }

      if (payload.autoShowPhotoId) {
        this.autoShowPhotoId = payload.autoShowPhotoId;
        const index: number = this.photos.findIndex((r: any) => r.photoId == payload.autoShowPhotoId);
        if (index >= 0) {
          setTimeout(() => { this.autoShowPhotoIndex = index }, 250);
        }
      }
    }

    async onPhotoFrameRemove(cartItemId: string): Promise<void> {
      this.isAddingFrame = true;

      const payload = { 
        cartId: this.cartState.cartId,
        cartItemId,
      };
      
      await this.cartState.removeItem(payload);
      if (this.cartState.isCartError) {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
        this.isAddingFrame = false;
        return;
      }

      await this.cartState.getCart({ cartId: this.cartState.cartId });
      this.photoFramesInCart = this.getFramesInCart();
      this.isAddingFrame = false;
    }

    async onPhotoFrameClosed(): Promise<void> {
      this.photoFrameDialog = false;
      this.selectedPhoto = null;
      this.selectedFrameProductId = "";
    }

    async onPhotosWithFrameAdd(isGift: boolean = false): Promise<void> {
      if (this.isAddingPhotosWithFrame) return;

      this.isAddingPhotosWithFrame = true;

      if (!this.cartState.cart || this.cartState.cartId == "") {
        const cartId: string = uuidv4();
        await this.cartState.createCart({ cartId: cartId });
      }
      
      const attributes = await this.getPhotosForAttributes();
      if (attributes.length === 0) {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString());
        this.isAddingPhotosWithFrame = false;
        return;
      }
      attributes.push(...this.getValuesForPhotosWithFrame());
      const payload = { 
        cartId: this.cartState.cartId,
        searchToken: this.getCurrentSearchToken(),
        item: {
          catalogId: this.eventId,
          productId: this.photosWithFrameProduct.productId,
          attributes: attributes,
          additionalAttributes: this.getFrameAttributes(),
        }
      };
      
      await this.cartState.addItem(payload);
      if (this.cartState.isCartError) {
        if (this.cartState.responseStatus == 409) {
          this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
        } else {
          this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
        }
        this.isAddingPhotosWithFrame = false;
        return;
      }

      await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      this.isAddingPhotosWithFrame = false;
      if (this.photosWithFrameDialog) {
        this.photosWithFrameOptions['alreadyInCart'] = this.isProductIdInCart(this.photosWithFrameProduct.productId, true);
      }
      this.photoFrameDialog = false;

      this.showPackageOffer(this.photosWithFrameProduct.productId);
      if (isGift) this.showSnackbar = true;
    }

    async onPhotosWithFrameGoCart(): Promise<void> {
      this.photosWithFrameDialog = false;
      setTimeout(async () => {
        this.disableVerticalScroll(false);
        await this.onGoToCart();
      }, 500);
    }

    async onPhotosWithFrameClosed(): Promise<void> {
      this.photosWithFrameDialog = false;
      this.photosWithFrameOptions = null;
      setTimeout(() => { this.disableVerticalScroll(false); }, 500);
    }

    async addGift(gift: any): Promise<void> {
      if (!gift) return;
      if (gift.product.productType === this.productTypes.photos_with_me) {
        await this.onAddPhotosWithMe(true);
      }
      if (gift.product.productType === this.productTypes.frame) {
        await this.showFramesPage(gift.product.productId, gift);
      }
      if (gift.product.productType === this.productTypes.photosWithFrame) {
        await this.onPhotosWithFrameAdd(true);
      }
    }

    async onSearchByTag(): Promise<void> {
      this.runnersDialog = true;
      this.runnersDialogKey = Math.random().toString();
    }

    async onRunnerChanged(hasResults: boolean): Promise<void> {
      if (hasResults) {
        setTimeout(async () => await this.updateRunnersHeight(), 100);
      } else {
        this.runnersHeight = "50px";
      }
    }

    async updateRunnersHeight(): Promise<void> {
      const r = document.getElementById(this.runnersContainerId);
      const f = document.getElementById('runnerSearchForm' + this.runnersDialogKey);
      if (r == null || f == null) return;

      const t = r.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const height = windowHeight - t - 16;

      const formHeight = f.offsetHeight;

      this.runnersHeight = Math.min(height, formHeight) + "px";
    }

    async onRunnerSelected(value: { 
      competitorId: string, 
      surname?: string, 
      firstName?: string, 
      number: string,
      time?: string,
      position?: number,
      distance?: string,
    }): Promise<void> {
      this.runnersDialog = false;
      this.runnerNum = value.number;
      this.runnerLastName = value.surname || "";
      const runnerFrameValues: any = {
        competitorId: value.competitorId || "",
      };
      if (value.surname) runnerFrameValues["surname"] = value.surname;
      if (value.firstName) runnerFrameValues["firstName"] = value.firstName;
      if (value.time) runnerFrameValues["time"] = value.time;
      if (value.position) runnerFrameValues["position"] = value.position.toString();
      if (value.distance) runnerFrameValues["distance"] = value.distance;

      this.runnerFrameValues = runnerFrameValues;

      this.settingsState.reachGoal("media_member_selected");

      this.loading = true;
      await this.searchByTag();
      this.loading = false;
    }

    async searchByTag(): Promise<void> {
      location.pathname = `/search/${this.eventsState.event.externalEventId}/${this.runnerNum}`;
    }

    async onSearchSelfie(): Promise<void> {
      if (!this.input) return;
      
      this.input.setAttribute('capture', 'camera');
      await this.onSearchByFace();
    }

    async onSearchUpload(): Promise<void> {
      if (!this.input) return;
      
      this.input.removeAttribute('capture');
      await this.onSearchByFace();
    }

    async onSearchCancelled(): Promise<void> {
      this.captureDialog = false;
    }

    async onSearchByFace(): Promise<void> {
      if (!this.input) return;
      
      if (this.captureDialog) {
        this.captureDialog = false;
      } else {
        this.input.removeAttribute('capture');
      }
      
      this.searchResult = "searching";
      
      if (!this.moreFaceImage) this.byFace = true;
      
      this.input.parentElement.reset();
      this.input.click();
    }

    async onPictureChange(): Promise<void> {
      if (!this.input) return;

      const curFiles = this.input.files;

      if (curFiles.length === 0) {
        this.selectedFile = null;
        this.redrawImage = false;
        this.$store.state.selectedFile = null;
      } else {
        this.loading = true;
        await this.loadImage(curFiles[0]);
        this.redrawImage = false;
        if (this.moreFaceImage) {
          this.disableVerticalScroll(true);
          setTimeout(() => {
            this.captureDialog = true;
            this.redrawImage = false;
            this.selectedFile = this.captureState.file;
            this.settingsState.reachGoal("media_selfie_uploaded");
            this.debouncedDoSearch();
            this.loading = false;
          }, 100);
          return;
        }
        this.captureDialog = true;
        this.redrawImage = false;
        this.selectedFile = this.captureState.file;
        this.settingsState.reachGoal("media_selfie_uploaded");
        this.debouncedDoSearch();
        this.loading = false;
      }
    }  

    async loadImage(selectedFile: File): Promise<void> {
      await this.captureState.loadImage(selectedFile);
      await this.captureState.loadFile(this.captureState.image.image.src);
      
      this.$store.state.selectedFile = this.captureState.file;
    }

    async searchByFace(): Promise<void> {
      this.redrawImage = true;

      await this.searchState.recognizePhoto({ file: this.selectedFile, albumId: this.eventId });
      if (this.searchState.searchError) {
        if (this.searchState.responseCode == 413) {
          this.searchResult = "too-big-file";
          return;
        } else if (this.searchState.responseCode == 500) {
          const result: boolean = await this.repeatSearchByFace();
          if (!result) return;
        } else {
          this.searchResult = "error";
          return;
        }
        this.captureDialog = false;
        return;
      }

      if (this.searchState.persons.length == 1) {

        const personId: string = this.searchState.persons[0].personId;
        if (!personId) {
          this.captureDialog = false;
          return;
        }

        if (!this.moreFaceImage) {
          location.pathname = `/search/${this.eventsState.event.externalEventId}/${this.searchState.selfieId + "+" + personId}`;
        } else {          
          const token: any = this.getCurrentSearchToken();
          const payloadForToken = {
            eventId: this.eventId,
            token,
            payload: { personId },
          };
          let tokenResult = await this.searchState.getToken(payloadForToken);
          if (tokenResult.status !== 200) {
            if (this.searchState.isTokenExpired && this.$props.recentSearch_) {
              const result: any = await this.getNewSearchToken({
                eventId: this.eventId,
                payload: {
                  startNumber: this.runnerNum,
                },
              });
              if (result) {
                payloadForToken.token = result.value;
                tokenResult = await this.searchState.getToken(payloadForToken);
                if (tokenResult.status !== 200) {
                  this.searchResult = "error";
                  return;
                }
              }
            } else {
              this.searchResult = "error";
              return;
            }
          }

          const params: any = {
            eventId: this.eventId,
            token: this.searchState.searchToken ? this.searchState.searchToken.value : '',
            pagination: { offset: 0, count: this.photosPerPage },
            additional: true,
          };
          
          await this.searchState.searchByTag(params);
          if (this.searchState.searchError) {
            this.searchResult = "error";
            return;
          }
          
          if (this.searchState.additionalPhotos.length > 0) {
            this.moreFaceImage = this.searchState.persons[0].faceUrl || "";
            this.findMoreResults = [...this.searchState.additionalPhotos];
            this.showNotFoundMore = false;
            this.showFindMoreResults = true;
          } else {
            this.searchResult = "not-found";
            return;
          }

          this.captureDialog = false;
          
          return;
        }

      } else if (this.searchState.persons.length == 0) {
        this.searchResult = "no-face";
      } else if (this.searchState.persons.length > 1) {
        this.searchResult = "many-people";
      } else {
        this.captureDialog = false;
      }
    }

    async repeatSearchByFace(): Promise<boolean> {
      await this.searchState.recognizePhoto({ file: this.selectedFile, albumId: this.eventId });
      
      if (this.searchState.searchError && this.searchState.responseCode == 413) {
        this.searchResult = "too-big-file";
        return false;
      } else if (this.searchState.searchError && this.searchState.responseCode == 500) {
        this.searchResult = "error";
        return false;
      } else if (this.searchState.searchError) {
        this.searchResult = "error";
        return false;
      }

      return true;
    }

    async onImageClick(type: number, index: number): Promise<void> {
      let photosCount = this.photos.length;

      if (this.$route.name === 'event-photos') {
        index = (this.currentPage - 1) * this.photosPerPage + index;
      }

      if (type == 1) {
        this.previewPhotos = await this.getPreviewPhotos(index);
      } else {
        this.previewPhotos = [];
      }
      this.autoShowPhotoIndex = -1;
      this.autoShowPhotoId = "";
      this.currentIndex = index;
      this.previewItsMe = false;
      
      if (photosCount == this.photos.length) {
        this.previewDialog = true;

      } else {
        setTimeout(() => {
            this.previewDialog = true;
          },
          250,
        );
      }
    }

    async onItsMeImageClick(index: number): Promise<void> {
      this.previewPhotos = await this.getItsMePreviewPhotos();

      this.autoShowPhotoIndex = -1;
      this.autoShowPhotoId = "";
      this.currentIndex = index;
      this.previewItsMe = true;
      
      this.previewDialog = true;
    }
    
    @Watch("autoShowPhotoIndex")
    async onAutoShowPhoto(): Promise<void> {
      if (this.autoShowPhotoIndex == -1) return;

      this.previewPhotos = this.photos;
      this.currentIndex = this.autoShowPhotoIndex;
      this.previewDialog = true;
    }

    @Watch("$props.backPageData_") 
    async onBackPageDataChanged(): Promise<void> {
      if (!this.$props.backPageData_) return;
      
      await this.cleanup();

      if (this.$props.backPageData_.name === "event") {
        await this.onBackToSearch();
        return;
      }

      await this.$router.push(this.$props.backPageData_);
    }

    @Watch("lang")
    async onLangChanged(): Promise<void> {
      await this.eventsState.getEventById(this.eventId);
      this.eventName = this.eventsState.event.title;

      this.setSelectedFrameValues();
      const distance: string = this.selectedFrameValues["distance"] || "";
      await this.catalogState.getProducts({ eventId: this.eventId, distance });
      await this.loadProducts();

      if (this.$route.name !== 'event-photos') {
        this.frameProducts = this.getCatalogProducts(this.productTypes.frame);
      } else {
        this.frameProducts = [];
      }
      
      await this.fillPackageOffers();
      await this.onPhotosWithMeChanged();
    }

    @Watch("loading")
    async onShow(): Promise<void> {
      setTimeout(() => { this.settingsState.setLoadingRoute(this.loading) }, 1000);
    }

    @Watch("captureDialog")
    async onCaptureDialogToggle(): Promise<void> {
      if (!this.captureDialog && this.moreFaceImage) {
        await this.recreateMasonry();
      }
    }

    @Watch("showPackageOffers")
    async onShowPackageOffersChanged(): Promise<void> {
      if (!this.showPackageOffers) {
        setTimeout(() => { this.disableVerticalScroll(false) }, 250);
      }
    }

    async cleanup(): Promise<void> {
      window.removeEventListener('scroll', this.checkForLoading);
      window.removeEventListener('scroll', this.checkForHalfPage);

      if (this.resultsMasonryGrid) {
        this.resultsMasonryGrid.destroy();
      }
      this.resultsMasonryGrid = null;

      this.isButtonInvisible = false;
      await this.settingsState.setAppBarHidden(this.isButtonInvisible);
    }

    async onItemMouseLeave(event: MouseEvent): Promise<void> {
      if (this.smOnly) return;

      if (this.hoveredItemIndex == -1 || this.photos.length-1 < this.hoveredItemIndex)
        return;

      const itemId = "results-item-" + this.photos[this.hoveredItemIndex].photoId;
      const item = document.getElementById(itemId);
      if (item == null) return;

      const rect = item.getBoundingClientRect();

      if ((event.clientY >= rect.top && 
          event.clientY <= (rect.top + rect.height) &&
          event.clientX <= (rect.left + rect.width) && 
          event.clientX >= rect.left)) {
        return;
      } else {
        this.hoveredItemIndex = -1;
      }
    }

    async onItemDropDown(value: boolean): Promise<void> {
      this.itemDropdown = value;
    }

    async initMasonry(): Promise<void> {
      const resultsGrid: any = this.$refs.searchResultsGrid;
      if (resultsGrid) {
        this.resultsMasonryGrid = new Masonry(resultsGrid, {
          itemSelector: ".search-results-grid__item",
          columnWidth: ".search-results-grid__sizer",
          percentPosition: true,
          initLayout: false,
        });

        const vm = this;

        vm.resultsMasonryGrid.once('layoutComplete', async () => {
          await vm.setupLoader();
          await vm.checkForLoading();
          await vm.setHalfPageListener();
        });
        vm.resultsMasonryGrid.layout();

        let resizeTimeout: any = null;

        window.addEventListener('resize', () => {
          clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(vm.reloadResultsGrid, 400);
        });
      }
    }
    
    async reloadResultsGrid(): Promise<void> {
      if (this.$route.name != 'search' && this.$route.name != 'search-from-url')
        return;

      if (this.resultsMasonryGrid) {
        this.resultsMasonryGrid.layout();
      }
    }

    async recreateMasonry(): Promise<void> {
      const vm = this;
      setTimeout(async () => {
        await this.cleanup();
        await this.initMasonry();
        window.addEventListener('scroll', vm.checkForLoading);
        await vm.checkForLoading();
        setTimeout(() => { this.disableVerticalScroll(false); }, 750);
        setTimeout(() => { this.scrollToMorePhotos(); }, 1000);
      }, 100);
    }

    async fillPhotos(): Promise<void> {
      this.photos = JSON.parse(this.$props.photos_);
      this.startFrom = this.photosPerPage;

      if (this.$route.name === 'event-photos') {
        const fromMobile = this.getIsMobileFromURL();
        const page: number = this.getPageNumber(fromMobile ? 12 : 50);
        if (!this.hasPhotoIdInURL()) {
          this.$router.replace({
            query: { p: page > 1 ? `${page}` : undefined, m: `${this.isMobile || this.isTablet}` },
          }).catch(() => {});          
        }
      }
    }

    async fillPhotosForPage(page: number): Promise<void> {
      this.updating = true;

      if (this.resultsMasonryGrid) {
        this.resultsMasonryGrid.destroy();
      }
      this.resultsMasonryGrid = null;

      window.scrollTo(0, 0);
      
      if (!this.allPhotosTokenValue) {
        const result: any = await this.getNewSearchToken({
          eventId: this.eventId,
          payload: {},
        });
        if (result) {
          this.allPhotosTokenValue = result.value;
        } else {
          this.updating = false;
          return;
        }
      }

      const payload: any = {
        eventId: this.eventId,
        token: this.allPhotosTokenValue,
        pagination: { offset: (page - 1) * this.photosPerPage, count: this.photosPerPage },
      };

      await this.searchState.getEventPhotos(payload);
      if (this.searchState.isTokenExpired) {
        const result: any = await this.getNewSearchToken({
          eventId: this.eventId,
          payload: {},
        });
        if (result) {
          this.allPhotosTokenValue = result.value;
          payload.token = result.value;
          await this.searchState.getEventPhotos(payload);
          if (this.searchState.searchError) {
            this.updating = false;
            return;
          }
        } else {
          this.updating = false;
          return;
        }
      }

      if (this.searchState.searchError) {
        this.updating = false;
        return;
      }

      const appendPhotos: any[] = Object.assign([], this.searchState.photos);

      if (appendPhotos.length == 0) {
        this.updating = false;
        return;
      }

      this.photos = appendPhotos;
      this.currentPage = page;

      const vm = this;
      this.$nextTick(async () => { 
        await vm.initMasonry();
        if (!this.hasPhotoIdInURL()) {
          this.$router.replace({
            query: { p: page > 1 ? `${page}` : undefined, m: `${this.isMobile || this.isTablet}` },
          }).catch(() => {});
        }
        this.updating = false;
      });
    }

    async getPhotosForPage(page: number, perPage: number): Promise<any[]> {      
      if (!this.allPhotosTokenValue) {
        const result: any = await this.getNewSearchToken({
          eventId: this.eventId,
          payload: {},
        });
        if (result) {
          this.allPhotosTokenValue = result.value;
        } else {
          return [];
        }
      }

      const payload: any = {
        eventId: this.eventId,
        token: this.allPhotosTokenValue,
        pagination: { offset: (page - 1) * perPage, count: perPage },
      };

      await this.searchState.getEventPhotos(payload);
      if (this.searchState.isTokenExpired) {
        const result: any = await this.getNewSearchToken({
          eventId: this.eventId,
          payload: {},
        });
        if (result) {
          this.allPhotosTokenValue = result.value;
          payload.token = result.value;
          await this.searchState.getEventPhotos(payload);
          if (this.searchState.searchError) {
            return [];
          }
        } else {
          return [];
        }
      }

      if (this.searchState.searchError) {
        return [];
      }

      return Object.assign([], this.searchState.photos);
    }

    async updatePhotos(): Promise<void> {
      const payload: any = {
        eventId: this.eventId,
        token: this.getCurrentSearchToken(),
        pagination: { offset: this.startFrom, count: this.photosPerPage },
      };

      if (this.$route.name === 'event-photos') {
        if (!this.allPhotosTokenValue) {
          const result: any = await this.getNewSearchToken({
            eventId: this.eventId,
            payload: {},
          });
          if (result) {
            this.allPhotosTokenValue = result.value;
          } else {
            await this.setUpdating(false);
            return;
          }
        }
        
        payload.token = this.allPhotosTokenValue;
        await this.searchState.getEventPhotos(payload);
        if (this.searchState.isTokenExpired) {
          const result: any = await this.getNewSearchToken({
            eventId: this.eventId,
            payload: {},
          });
          if (result) {
            this.allPhotosTokenValue = result.value;
            payload.token = result.value;
            await this.searchState.getEventPhotos(payload);
            if (this.searchState.searchError) {
              await this.setUpdating(false);
              return;
            }
          } else {
            await this.setUpdating(false);
            return;
          }
        }
      } else {
        await this.searchState.searchByTagForPreview(payload);
        if (this.searchState.isTokenExpired) {
          const result: any = await this.getNewSearchToken({
            eventId: this.eventId,
            payload: {
              startNumber: !this.byFace ? this.runnerNum : undefined,
              personId: this.byFace ? this.personId : undefined,
            },
          });
          if (result) {
            payload.token = result.value;
            await this.searchState.searchByTagForPreview(payload);
            if (this.searchState.searchError) {
              await this.setUpdating(false);
              return;
            }
          } else {
            await this.setUpdating(false);
            return;
          }
        }
      }

      const appendPhotos: any[] = Object.assign([], this.searchState.photosPreview);

      if (appendPhotos.length == 0) {
        await this.setUpdating(false);
        return;
      }

      this.photos.push(...appendPhotos);

      const vm = this;
      this.$nextTick(async () => { 
        await vm.updateElements(appendPhotos);
      });
    }

    async getPreviewPhotos(currentIndex: number): Promise<any[]> {
      const payload: any = {
        eventId: this.eventId,
        token: '',
        pagination: { offset: 0, count: this.photosPerPage },
      };
      const previewPhotos: any[] = Array(this.searchState.photosTotal);
      const page: number = currentIndex == 0 ? 0 : Math.floor(currentIndex / this.photosPerPage);
      payload.pagination = { offset: (this.photosPerPage * page), count: this.photosPerPage };

      if (this.$route.name === 'event-photos') {
        if (!this.allPhotosTokenValue) {
          const result: any = await this.getNewSearchToken({
            eventId: this.eventId,
            payload: {},
          });
          if (result) {
            this.allPhotosTokenValue = result.value;
          } else {
            return [];
          }
        }
        
        const payload: any = {
          eventId: this.eventId,
          token: this.allPhotosTokenValue,
          pagination: { offset: (this.photosPerPage * page), count: this.photosPerPage },
        };
        await this.searchState.getEventPhotosForPreview(payload);
        if (this.searchState.isTokenExpired) {
          const result: any = await this.getNewSearchToken({
            eventId: this.eventId,
            payload: {},
          });
          if (result) {
            this.allPhotosTokenValue = result.value;
            payload.token = result.value;
            await this.searchState.getEventPhotosForPreview(payload);
            if (this.searchState.searchError) {
              return [];
            }
          } else {
            return [];
          }
        }
      } else {
        payload.token = this.getCurrentSearchToken(),
        await this.searchState.searchByTagForPreview(payload);
        if (this.searchState.isTokenExpired) {
          const result: any = await this.getNewSearchToken({
            eventId: this.eventId,
            payload: {
              startNumber: !this.byFace ? this.runnerNum : undefined,
              personId: this.byFace ? this.personId : undefined,
            },
          });
          if (result) {
            payload.token = result.value;
            await this.searchState.searchByTagForPreview(payload);
            if (this.searchState.searchError) {
              return [];
            }
          } else {
            return [];
          }
        }
      }

      if (this.searchState.photosPreview.length > 0) {
        for (let i = 0; i < this.searchState.photosPreview.length; i++) {
          previewPhotos[(this.photosPerPage * page) + i] = Object.assign({}, this.searchState.photosPreview[i]);
        }
      }

      return previewPhotos;
    }

    async getItsMePreviewPhotos(): Promise<any[]> {
      const previewPhotos: any[] = Array(this.findMoreResults.length);

      for (let i = 0; i < this.findMoreResults.length; i++) {
        previewPhotos[i] = Object.assign({}, this.findMoreResults[i]);
        previewPhotos[i]["itsme"] = this.itsMePhotos.includes(this.findMoreResults[i].photoId);
      }

      return previewPhotos;
    }

    async updateElements(available: any[], usePhotosAll: boolean = false): Promise<void> {
      const grid = document.getElementsByClassName('search-results-grid')[0];
      const items: any = grid.getElementsByClassName('search-results-grid__item');
      let firstIndex: number = items.length - available.length;
      if (firstIndex < 0) firstIndex = 0;
      
      const newElems: any[] = [];
      for (let i = firstIndex; i < items.length; i++) {
        newElems.push(items[i]);
      }

      if (newElems.length > 0) {
        await this.updateMasonry(newElems, usePhotosAll);
      } else {
        await this.setUpdating(false);
      }
    }

    async updatePhotosInCart(): Promise<void> {
      const photoIds: string[] = [];

      if (this.cartState.cartId != "" && this.cartState.cartItems.length > 0) {
        const cartItems = this.cartState.cartItems;

        for (let i = 0; i < cartItems.length; i++) {
          const items: string[] = this.getItemPhotos(cartItems[i]);
          if (items.length == 0) continue;

          photoIds.push(...items);
        }
      }

      this.photosInCart = photoIds;
    }

    async updateMasonry(items: any, usePhotosAll: boolean = false): Promise<void> {
      const vm = this;
      setTimeout(async () => {    
          if (!vm.resultsMasonryGrid)
            return;

          vm.resultsMasonryGrid.once('layoutComplete', 
            async () => {
              if (usePhotosAll) {
                vm.startFrom = vm.searchState.photosTotal;
              } else if (items.length > 0) 
                vm.startFrom += this.photosPerPage;

              await vm.setUpdating(false);
            } 
          );

          vm.resultsMasonryGrid.appended(items);
        },
        500,
      );
    }

    async setUpdating(updating: boolean): Promise<void> {
      const vm = this;
      setTimeout(() => {
          vm.updating = updating;
        }, 
        500,
      );
    }

    async updateButtonInvisible(): Promise<void> {
      const button = document.getElementById('list__title-button');
      if (!button) return;

      const bottom = button.getBoundingClientRect().bottom;
      const invisible = this.smOnly ? bottom <= 56 : bottom <= 64;

      await this.settingsState.setAppBarHidden(invisible);
      this.isButtonInvisible = invisible;
    }

    async setupLoader(): Promise<void> {
      const vm = this;
      if (vm.photoLoaderBlock) return;
      setTimeout(async () => {
          if (vm.$route.name != 'search' 
              && vm.$route.name != 'search-from-url')
            return;

          const loaders = document.getElementsByClassName('search-results__loader');
          vm.photoLoaderBlock = loaders.length > 1 ? loaders[1] : null;
          window.addEventListener('scroll', vm.checkForLoading);
          await vm.checkForLoading();
        }, 
        250,
      );
    }

    async checkForLoading(): Promise<void> {
      if (this.loading) return;
      if (this.updating) return;
      if (!this.photoLoaderBlock) return;
      if (this.photos.length == 0) return;
      if (this.$route.name != 'search' 
          && this.$route.name != 'search-from-url') {
        await this.cleanup();
        return;
      }

      await this.updateButtonInvisible();

      if (this.startFrom >= this.searchState.photosTotal)
        return;

      if (!this.resultsMasonryGrid)
        return;
        
      const grid = document.getElementsByClassName('search-results-grid')[0];
      if (!grid) return;
      const items = grid.getElementsByClassName('search-results-grid__item');

      if (items.length >= this.searchState.photosTotal)
        return;

      let bottomCoord = this.photoLoaderBlock.getBoundingClientRect().bottom;
      let height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        if (this.updating) return;
        this.updating = true;
        setTimeout(async () => { 
            await this.updatePhotos();
          }, 
          250
        );
      }
    }

    async checkForHalfPage(): Promise<void> {
      const resultsGrid: any = this.$refs.searchResultsGrid;
      if (resultsGrid) {
        const topCoord = resultsGrid.getBoundingClientRect().top;
        const bottomCoord = resultsGrid.getBoundingClientRect().bottom;
        const halfPage = topCoord + ((bottomCoord - topCoord) / 2);
        const height = document.documentElement.clientHeight;
        if (height >= halfPage) {
          await this.addFindMoreNotification();
        }
      }
    }

    async loadProducts(): Promise<void> {
      const items: any[] = [ ...this.catalogState.products ];
      
      for (let i = 0; i < items.length; i += 1) {
        const found: any[] = this.catalogProducts.filter((r: any) => r.productId === items[i].productId);
        if (found.length > 0) continue;
        
        await this.catalogState.getProduct({ productId: items[i].productId });

        if (this.catalogState.product) {
          const product: any = { ...this.catalogState.product };
          product["productId"] = items[i].productId;
          product["type"] = items[i].productType;
          
          this.catalogProducts.push(product);
        }
      }
    }

    async fillPackageOffers(): Promise<void> {
      const result: any[] = [];

      if (this.photosWithMeProduct
        && this.photosWithMeDiscount > 0
        && this.$route.name !== 'event-photos'
      ) {
        result.push({
          title: this.$t('productPreview/productAllPhotos'),
          productId: this.photosWithMeProduct.productId,
          productType: this.photosWithMeProduct.productType,
          price: this.photosWithMeProduct.amount,
          size: 0,
          oldPrice: this.photoProduct ? this.photoProduct.amount * this.searchState.photosTotal : 0,
        });
      }

      if (this.photosWithFrameProduct) {
        let offers = Object.assign([], this.photosWithFrameProduct.packageOffers);
        if (offers.length > 0) {
          offers = offers.sort(this.sortOffers);
          offers = offers.map((i: any) => {
            return {
              ...i,
              title: this.photosWithFrameProduct.title,
              productId: this.photosWithFrameProduct.productId,
              productType: this.photosWithFrameProduct.productType,
              frameId: Common.getAttribute(this.photosWithFrameProduct.attributes, 'FrameId'),
              oldPrice: this.photosWithFrameProduct.amount * i.size,
            }
          });
          if (offers.length > 0 && offers[0].size !== 1) {
            const one = { ...offers[0] };
            one.size = 1;
            one.price = this.photosWithFrameProduct.amount;
            one.oldPrice = this.photosWithFrameProduct.amount;
            offers = [one, ...offers];
          }
          result.push(...offers);
        }
      }

      if (this.photoProduct) {
        let offers = Object.assign([], this.photoProduct.packageOffers);
        if (offers.length > 0) {
          offers = offers.sort(this.sortOffers);
          offers = offers.map((i: any) => {
            return {
              ...i,
              title: this.$t('productPreview/productDigitalPhoto').toString(),
              productId: this.photoProduct.productId,
              productType: this.photoProduct.productType,
              oldPrice: this.photoProduct.amount * i.size,
            }
          });
          if (offers.length > 0 && offers[0].size !== 1) {
            const one = { ...offers[0] };
            one.size = 1;
            one.price = this.photoProduct.amount;
            one.oldPrice = this.photoProduct.amount;
            offers = [one, ...offers];
          }
          result.push(...offers);
        }
      }

      if (this.frameProducts.length > 0) {
        for (let i = 0; i < this.frameProducts.length; i++) {
          let offers = Object.assign([], this.frameProducts[i].packageOffers);
          if (offers.length > 0) {
            offers = offers.sort(this.sortOffers);
            offers = offers.map((r: any) => {
              return {
                ...r,
                title: this.frameProducts[i].title,
                productId: this.frameProducts[i].productId,
                productType: this.frameProducts[i].productType,
                frameId: Common.getAttribute(this.frameProducts[i].attributes, 'FrameId'),
                oldPrice: this.frameProducts[i].amount * r.size,
              }
            });
            if (offers.length > 0 && offers[0].size !== 1) {
              const one = { ...offers[0] };
              one.size = 1;
              one.price = this.frameProducts[i].amount;
              one.oldPrice = this.frameProducts[i].amount;
              offers = [one, ...offers];
            }
            result.push(...offers);
          }
        }
      }

      if (this.promocodeProduct) {
        let offers = Object.assign([], this.promocodeProduct.packageOffers);
        if (offers.length > 0) {
          offers = offers.sort(this.sortOffers);
          offers = offers.map((i: any) => {
            return {
              ...i,
              title: this.promocodeProduct.title,
              productId: this.promocodeProduct.productId,
              productType: this.promocodeProduct.productType,
              oldPrice: this.promocodeProduct.amount * i.size,
            }
          });
          if (offers.length > 0 && offers[0].size !== 1) {
            const one = { ...offers[0] };
            one.size = 1;
            one.price = this.promocodeProduct.amount;
            one.oldPrice = this.promocodeProduct.amount;
            offers = [one, ...offers];
          }
          result.push(...offers);
        }
      }

      const filtered: any[] = [];
      for (let i = 0; i < result.length; i++) {
        if (result[i].size <= this.searchState.photosTotal) {
          filtered.push(result[i]);
        }
      }

      this.packageOffers = filtered;

    }

    async setFindMore(): Promise<void> {
      if (this.$route.name === 'event-photos') return;

      let showFindMore = this.$props.searchToken_ ? this.$props.searchToken_.complementable === true : false;
      if (showFindMore) {      
        this.moreByPhoto = !this.byFace;
      }

      if (this.byFace && !this.tagged) showFindMore = false;
      if (!this.byFace && !this.isFaceSearchEnabled) showFindMore = false;

      this.showFindMore = showFindMore;
    }

    async setHalfPageListener(): Promise<void> {
      if (!this.showFindMore) return;
      const vm = this;
      window.addEventListener('scroll', vm.checkForHalfPage);
    }

    async addFindMoreNotification(): Promise<void> {
      if (!this.showFindMore) return;
      if (this.showFindMoreNotification) return;

      const moreByPhoto = this.moreByPhoto;
      const props: any = {
        title: moreByPhoto ? this.$t('findMore/notificationTitleSelfie').toString() : this.$t('findMore/notificationTitleNumber').toString(),
        img: moreByPhoto ? '/img/notification-selfie.svg' : '/img/notification-bib.svg',
        discountText: '',
        declineText: this.$t('findMore/notificationSecondary').toString(),
        confirmText: moreByPhoto ? this.$t('findMore/notificationPrimarySelfie').toString() : this.$t('findMore/notificationPrimaryNumber').toString(),
        confirmIconRight: moreByPhoto ? 'selfie' : 'reedem',
      };
      const handlers: any = {
        confirm: () => {
          // Выполнить primary-действие
          if (moreByPhoto) this.onMoreSearch({ faceImage: 'true' });
          else this.scrollToMorePhotos();
        },
        decline: () => {
          // Скрыть уведомление насовсем
        },
        dismiss: () => {
          // Скрыть уведомление временно
        },        
      };

      const root: any = this.$root;
      root.$addNotification({ props, ...handlers });
      this.showFindMoreNotification = true;
    }

    async showPreviewFromURL(): Promise<void> {
      const routes = ['event-photos', 'search', 'search-from-url'];
      if (!routes.includes(this.$route.name || '')) return;

      if (!this.hasPhotoIdInURL()) return;

      const w: any = window;
      if (!w.PhotoSwipe || typeof(w.PhotoSwipe) !== 'function') {
        setTimeout(() => { this.showPreviewFromURL(); }, 100);
        return;
      }

      this.autoShowPhotoIndex = -1;
      this.autoShowPhotoId = '';
      this.previewItsMe = false;

      const photoId = this.getPhotoIdFromURL();
      const fromMobile = this.getIsMobileFromURL();

      if (this.$route.name === 'event-photos') {
        const page = this.getPageNumber(fromMobile ? 12 : 50);
        this.currentIndex = (page - 1) * (fromMobile ? 12 : 50);

        this.previewPhotos = await this.getPreviewPhotos(this.currentIndex);
        const index = this.previewPhotos.findIndex((item) => item && item.photoId === photoId);
        if (index >= 0) {
          setTimeout(() => { this.previewDialog = true; }, 250);
          return;
        }
        this.$router.replace({
          query: { p: page > 1 ? `${page}` : undefined, m: `${this.isMobile || this.isTablet}` },
        }).catch(() => {});
        return;
      }
      
      if (this.$route.name !== 'event-photos') {
        for (let i = 0; i < this.totalPages; i += 1) {
          this.previewPhotos = await this.getPreviewPhotos(i * this.photosPerPage);
          const index = this.previewPhotos.findIndex((item) => item && item.photoId === photoId);
          if (index >= 0) {
            this.currentIndex = index;
            setTimeout(() => { this.previewDialog = true; }, 250);
            return;
          }
        }
        this.$router.replace({ query: { m: `${this.isMobile || this.isTablet}` }, }).catch(() => {});
      }
    }

    async processEventPhotosPage(): Promise<void> {
      const fromMobile = this.getIsMobileFromURL();
      const needPageRecalc = fromMobile !== null ? fromMobile !== (this.isMobile || this.isTablet) : false;

      if (needPageRecalc) {
        const page = this.getPageNumber(fromMobile ? 12 : 50);
        if (page === -1) {
          this.$router.replace({ query: { m: `${this.isMobile || this.isTablet}` }, }).catch(() => {});
          return;
        }
        const photos = await this.getPhotosForPage(page, fromMobile ? 12 : 50);
        const photoId = this.getPhotoIdFromURL();
        const index = photos.findIndex((i) => i.photoId === photoId);
        if (index < 0) {
          this.$router.replace({ query: { m: `${this.isMobile || this.isTablet}` }, }).catch(() => {});
          return;
        }

        const absoluteIndex = ((page - 1) * (fromMobile ? 12 : 50)) + index;
        const newPage = Math.ceil((absoluteIndex + 1) / this.photosPerPage);

        this.$router.replace({
          query: { p: newPage > 1 ? `${newPage}` : undefined, m: `${this.isMobile || this.isTablet}`, id: photoId },
        }).catch(() => {});
        await this.fillPhotosForPage(newPage);

        return;
      }

      const page = this.getPageNumber(fromMobile ? 12 : 50);
      if (page === -1) {
        this.$router.replace({ query: { m: `${this.isMobile || this.isTablet}` }, }).catch(() => {});
        await this.fillPhotos();
      } else if (page === 1) {
        await this.fillPhotos();
      } else {
        await this.fillPhotosForPage(page);
      }
    }

    async created(): Promise<void> {
      this.runnersContainerId = "runners" + Math.random().toString();

      this.debouncedDoSearch = Debounce(this.searchByFace, 500);

      await this.updateRunnersHeight();

      window.addEventListener("resize", async () => {
          await this.updateRunnersHeight();
        }
      );
    }

    async mounted(): Promise<void> {
      this.eventId = this.$props.eventId_;
      this.eventName = this.$props.eventName_;
      this.eventDate = this.$props.eventDate_;
      this.isAllPhotosVisible = this.$props.isAllPhotosVisible_;
      this.runnerNum = this.$props.runnerNum_;
      this.runnerFrameValues = this.$props.runnerFrameValues_ ? JSON.parse(this.$props.runnerFrameValues_) : null;

      this.byFace = (this.$props.byFace_ == "true");
      this.faceImage = this.$props.faceImage_;
      this.selfieId = this.$props.selfieId_;
      this.personId = this.$props.personId_;

      await this.updatePhotosInCart();
      
      this.notFound__ = this.$props.notFound_;

      this.tagged = await this.isTagged();

      this.updating = true;
      if (this.$route.name !== 'event-photos') {
        await this.fillPhotos();
      }
      if (this.$route.name === 'event-photos') {
        await this.processEventPhotosPage();
      }
      await this.setFindMore();
      this.updating = false;

      this.setSelectedFrameValues();
      const distance: string = this.selectedFrameValues["distance"] || "";
      await this.catalogState.getProducts({ eventId: this.eventId, distance });
      await this.loadProducts();

      if (this.photoProduct) {
        this.itemPhotoPrice = this.photoProduct.amount;
      }
      if (this.$route.name !== 'event-photos') {
        this.frameProducts = this.getCatalogProducts(this.productTypes.frame);
        if (this.itemPhotoPrice > 0) {
          this.settingsState.reachGoal("paid_photo_search");
        } else {
          this.settingsState.reachGoal("free_photo_search");
        }
      }

      await this.fillPackageOffers();

      await this.updateSbpState();

      await this.showPreviewFromURL();

      this.loading = false;

      this.setPictureHandlers();

      await this.updateRunnersHeight();

      await this.settingsState.setAppBarHidden(false);

      const vm = this;
      this.$nextTick(async () => { 
        if (vm.resultsMasonryGrid === null && vm.photos.length > 0) {
          setTimeout(async () => {
            await vm.initMasonry();
          }, 500);
        }
      });

    }

  }
